@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Italianno&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500 !important;
  color: #000 !important;
  background-color: #fafafa !important;
}
body iframe {
  z-index: -10 !important;
}
img {
  max-width: 100%;
}
.brand-logo img {
  height: 42px;
}
.header {
  background: #fff;
}
.step-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.progress-section .step {
  position: relative;
  color: #757575 !important;
  font-weight: 700;
  font-size: 10px;
  text-transform: uppercase;
  padding-right: 30px;
}
.step-text {
  position: relative;
}
.step-text:after {
  content: "";
  height: 2px;
  width: 15px;
  position: absolute;
  top: 6px;
  right: -22px;
  background-color: var(--green);
}
.last-step {
  padding-right: 0px !important;
}
.last-step .step-text:after {
  display: none;
}
.active-link .step-nembering {
  background-color: var(--green);
  color: #fff;
}
.progress-section .step:hover {
  text-decoration: none !important;
}
.step-nembering {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: var(--green);
  border-radius: 50%;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  border: 2px solid var(--green);
  line-height: 16px;
  margin-right: 6px;
}
.heading {
  font-weight: 400;
  font-size: 48px;
  line-height: 1.077;
}
.text-warn {
  color: var(--green);
  font-weight: 700;
}
.list-steps {
  padding-left: 0px;
  list-style: none;
  margin-top: 25px;
  position: relative;
}
.list-steps li .number {
  background: var(--green);
  height: 27px;
  display: inline-flex;
  width: 27px;
  color: var(--white);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
  position: absolute;
  left: 0;
}
.list-steps li {
  display: inline-flex;
  margin-bottom: 22px;
  padding-left: 48px;
}
.list-steps li p {
  margin-bottom: 0px;
  font-size: 18px;
  word-break: break-word;
}
.parent-col {
  word-break: break-word !important;
}
/* .step-box,
.summary-box {
  position: relative;
  min-height: calc(100vh - 76px) !important;
} */
.left-box-screen,
.right-box-screen {
  min-height: calc(100vh - 167px) !important;
  display: flex;
  align-items: center;
}
.right-box-screen {
  justify-content: center;
}
.step-box:after {
  content: "";
  height: 100%;
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  background: #f6f8fa;
  z-index: -1;
}
/* .summary-box:after,
.summary-box:before {
  content: "";
  height: 100%;
  width: 56%;
  position: absolute;

  top: 0;

  z-index: -1;
} */
/* .summary-box:after {
  left: 0;
  background: #f6f8fa;
} */
.summary-box:before {
  right: 0;
  background-color: var(--white);
}
.screen-left {
  padding-left: 0%;
  padding-right: 19%;
}
.screen-right,
.screen-left {
  padding-top: 20px;
  padding-bottom: 20px;
}
.screen-right {
  padding-left: 20px;
  padding-left: 7%;
}
/* experience page css */
.exp-level-btn {
  padding: 21px 5px;
  margin-bottom: 12px;
  font-size: 20px;
  line-height: 16px;
  background-color: #fbfbfb;
  min-width: 205px;
  text-transform: capitalize;
  color: #000;
  border: 1px solid #919191 !important;
  margin: 15px;
  min-height: 87px;
  font-weight: 500;
}
.exp-level-btn:hover,
.exp-level-btn:focus {
  background-color: var(--white);
  box-shadow: 0 0 6px rgb(88 88 95 / 11%);
  color: #000;
  border: 2px solid var(--yellow) !important;
  /* border-color: var(--yellow) !important; */
}
.page-title {
  font-size: 21px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 5px;
  font-weight: 400;
}
.exp-content-box {
  padding: 4rem 0;
  margin-top: 10vh;
  transition: all 0.5s;
  text-align: center;
}
span.info-text-box {
  /* position: absolute; */
  /* top: 0; */
  /* right: 0; */
  position: relative;
}

.info-content-box {
  position: absolute;
  display: none;
  left: 50px;
  width: 220px;
  background: #fff;
  font-size: 12px;
  padding: 10px;
  border: 1px solid #d7dde2;
  box-shadow: 0 0 10px 2px rgb(17 17 17 / 4%);
  top: -28px;
}
.info-text-box i.fa {
  opacity: 0.25;
  margin-left: 10px;
  position: absolute;
  font-size: 16px;
}
.info-content-box:before {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  left: -13px;
  border-width: 0 9px 11px;
  border-bottom-color: #fff;
  top: 25px;
  transform: rotate(-90deg);
  z-index: 2;
}
.info-content-box:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  left: -14px;
  border-width: 0 9px 11px;
  border-bottom-color: #d7dde2;
  top: 25px;
  transform: rotate(-90deg);
  z-index: 1;
}
span.info-text-box:hover .info-content-box {
  display: inline-block;
}
.condition-box {
  min-height: 220px;
}
/* select country css */
.select-country-box {
  width: 345px;
  margin: auto;
  max-width: 100%;
}
/* choose-resume page */
.resume-color-list {
  list-style: none;
  padding-left: 15px;
}
.resume-color-list .color-item {
  display: inline-block;
}
.color-selector-radio {
  height: 25px;
  width: 25px;
  display: inline-block;
  position: relative;
  border-radius: 0px;
  cursor: pointer;
  /* z-index: 1; */
}
.color-selector {
  position: relative;
  margin-bottom: 10;
  margin-right: 10px;
}
.color-selector .color-input {
  position: absolute;
  opacity: 0;
  /* z-index: 2; */
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}
/* .color-input:checked + .color-selector-radio{
  background-color: blue !important;
} */
.color-selector:hover {
  opacity: 0.8;
}
.color-input:checked + .color-selector-radio i.fa.fa-check {
  opacity: 1;
}
.color-selector-radio i.fa.fa-check {
  color: #fff;
  opacity: 0;
}
.root-color .color-selector-radio {
  border: 2px solid var(--light-grey) !important;
  position: relative;
  cursor: pointer;
  position: relative;
  top: 2px;
}
.root-color .color-selector-radio:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 19px;
  top: 10px;
  left: 1px;
  background: var(--root-color);
  transform: rotate(-41deg);
}
/* template page css */
.template-box {
  box-shadow: 0 0 30px 0 rgb(88 88 95 / 20%);
}
.template-box:hover {
  opacity: 0.5;
  cursor: pointer;
}
/* select-resume-page css */
.build-resume-box {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resume-box-content {
  padding-top: 45px;
  width: 315px;
  min-height: 225px;
  text-align: center;
  box-shadow: 0 0 6px rgb(88 88 95 / 11%);
  margin: 20px;
}
.create-resume {
  border: 1px solid var(--blue);
}
.create-resume * {
  color: #333;
}
.already-resume {
  border: 1px solid #e8ecf0;
}
.resume-box-content a,
.resume-box-content a:hover {
  color: inherit;
  text-decoration: none;
}
.option-texts {
  font-size: 13px;
}
/* drag reusme */
.custom-input-file {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.drag-resume {
  background-color: rgba(58, 150, 240, 0.05);
  text-align: center;
  border: 2px dashed #3a96f0;
  cursor: pointer;
}
.browse-btn {
  font-size: 16px !important;
  padding: 7px 30px !important;
  border: none !important;
}
/* resume-contact-form */
.resume-contact-section .container {
  padding-left: 0px;
  padding-right: 0px;
}
.resume-contact-form {
  color: #3b3b3b !important;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 4px;
}
form input,
.check-vd-input input,
form select {
  border: 1px solid var(--light-dark) !important;
  font-size: 14px !important;
  height: calc(1.9em + 0.75rem + 2px) !important;
  border-radius: 0rem;
}
form input:focus,
.check-vd-input input:focus,
form select:focus {
  box-shadow: none !important;
  /* border: 1px solid var(--blue) !important; */
  outline: none;
}
.resume-preview img {
  /* zoom: 0.3; */
  border: 1px solid var(--light-grey);
  cursor: pointer;
  height: 300px;
}
.resume-preview img:hover {
  border: 1.5px solid var(--dark-blue);
}
.resume-pre-dialog {
  max-width: 850px !important;
}
.resume-pre-dialog .modal-content {
  border-radius: 0px;
  border: none;
}
.resume-pre-dialog .modal-content {
  border-radius: 0px;
  border: none;
  height: 86vh;
  overflow-y: auto;
}
.modal-dialog-recom .modal-content {
  height: auto;
}
.resume-pre-dialog .modal-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.resume-pre-dialog .modal-content::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}
.resume-pre-dialog .modal-content::-webkit-scrollbar-thumb {
  background-color: var(--blue);
}
.resume-pre-dialog button.close {
  position: absolute;
  right: 15px;
  opacity: 1;
}
.tips-list {
  list-style: none;
  padding-left: 0;
  font-size: 18px;
}

.tips-list li {
  font-size: 18px;
  line-height: 28px;
}
/* eductaion detail form */
.slect-box {
  position: relative;
}
.select-icon {
  position: absolute;
  right: 0;
  top: 7px;
  font-size: 18px;
  right: 15px;
  color: var(--light-dark);
}
.slect-box select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
/* description box css */
.card-des-box p.btn.collapsed .acc-icon-plus .fa {
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
.card-des-box p.btn {
  color: var(--blue);
}
.acc-icon-plus .fa {
  font-size: 14px;
  margin-right: 10px;
}
.card-des-box p.btn .acc-icon-plus .fa {
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}
.des-card {
  border: none !important;
  background: transparent !important;
}
.card-des-box p.btn {
  padding: 5px 0px;
}
.editor-wrapper {
  border: 1px solid var(--light-grey);
  background: var(--white);
  display: flex;
  flex-wrap: wrap;
}
.list-editor,
.draft-editor-box {
  width: 50%;
}
.list-editor {
  background: #f2f2f2;
  padding: 20px;
  border-right: 1px solid var(--light-grey);
  height: 364px;
  overflow: hidden;
  overflow-y: auto;
}

.list-editor::-webkit-scrollbar-track {
  background-color: #fff;
}
.list-editor::-webkit-scrollbar {
  width: 9px;
  background-color: #fff;
}
.list-editor::-webkit-scrollbar-thumb {
  background-color: var(--light-grey);
  border-radius: 0px;
}
.list-editor-inner {
  padding-left: 0px;
  list-style: none;
}
.list-editor-inner .list-editor-item {
  display: flex;
  display: flex;
  align-items: stretch;
  min-height: 60px;
  border: 1px solid #ddd;
  background: #fff;
  margin-top: 10px;
  cursor: pointer;
}
.list-editor-item .item-left {
  position: relative;
  width: 23px;
  background-color: var(--blue);
  align-self: stretch;
  border-right: 1px solid #ddd;
  min-height: 60px;
  min-width: 23px;
}
.skill-list-box .list-editor-item .item-left {
  min-height: 40px !important;
  min-width: 40px !important;
  height: 40px;
  width: 40px;
  position: relative;
  top: 10px;
}
.skill-list-box .item-right {
  padding-left: 10px;
}
.skill-list-box.list-center .list-editor-item .item-left {
  top: 0 !important;
}
ul.list-editor-inner .item-right {
  padding: 7px;
}
.list-editor-item .item-left button {
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.skill-list-box .list-editor-item .item-left button {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.list-editor-item .item-left input[type="checkbox"] {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.list-editor-item .item-left input[type="checkbox"]:checked + button:after {
  display: block;
}
.list-editor-item .item-left input[type="checkbox"]:checked + button:before {
  display: none;
}
/* .list-editor-item .item-left button:before {
  content: "- ADD";
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  white-space: nowrap;
  display: none;
} */
/* .list-editor-item .item-left button:after {
}
.list-editor-item .item-left input[type="checkbox"]{
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}
.list-editor-item .item-left input[type="checkbox"]:checked + button:after{
  display: block;
}
.list-editor-item .item-left input[type="checkbox"]:checked + button:before{
  display: none;
} 
.list-editor-item .item-left button:after {
    content: "- REMOVE";
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
    display: none;
}
.list-editor-item .item-right {
  width: calc(100% - 22px);
  padding: 12px;
  padding: 12px 15px;
  font-size: 14px;
  align-items: center;
  display: flex;
}
/* education summary */
.paragroup-item {
  position: relative;
  border: 1px solid #e8ecf0;
  display: block;
  padding: 20px 115px 0 60px;
  margin-bottom: 20px;
  height: auto;
  background-color: #fff;
}
.para-count:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 47px 47px 0 0;
  border-color: var(--blue) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  z-index: -1;
  display: inline-block;
}
.para-count {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  text-indent: 9px;
  line-height: 30px;
}
.education-del-para p .edu-tag:after {
  content: "";
  position: absolute;
  height: 14px;
  width: 1px;
  left: 0;
  top: 3px;
  background: #777;
}
.education-del-para p .edu-tag {
  position: relative;
  padding: 0px 8px;
}
.education-del-para p {
  line-height: 1.3;
}
.des-link {
  font-size: 13px;
}
.para-toolbar {
  position: absolute;
  top: 9px;
  right: 10px;
}
.para-toolbar button {
  padding: 0;
  border: none;
  background: transparent;
  color: var(--blue);
  margin-left: 10px;
}
.para-toolbar a {
  background-color: rgba(0, 0, 0, 0);
  min-width: auto;
  padding: 10px;
  margin: 0 !important;
  border: 0;
}
/* work history css */
.work-tip-list {
  padding-left: 15px;
}
.date-picker-box {
  position: relative;
}
.calendar-icon {
  position: absolute;
  top: 10px;
  color: #9b9b9b;
  left: 15px;
}
/* .date-picker-box .DatePicker {
    width: 100%;
}
.date-picker-box 
 input.DatePicker__input.-ltr {
    width: 100%;
    text-align: left;
    background: transparent;
    padding-left: 40px;
}
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween){
  background-color: var(--blue) !important;
    color: #fff !important;
}
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after{
  display: none !important;
}
.Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd,
.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText{
  background-color: var(--blue) !important;
}
.Calendar__weekDays{
  color: var(--blue) !important;
} */

.date-picker-box input[type="date"] {
  width: 100%;
  padding-left: 40px;
  background: transparent;
  color: #495057;
}
.date-picker-box input[type="date"]:focus {
  border: 1px solid var(--blue) !important;
  outline: none !important;
}
.date-picker-box
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.date-picker-box
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  display: none;
}
.date-picker-box .react-datepicker {
  border: 1px solid var(--green);
  border-radius: 0;
}
.react-datepicker__month-text.react-datepicker__month--selected:hover {
  background-color: var(--green) !important;
}
.date-picker-box .react-datepicker__header,
.date-picker-box
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  background-color: var(--green);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-top: 10px;
}
.date-picker-box .react-datepicker__navigation-icon {
  top: 7px;
}
.date-picker-box .react-datepicker__navigation-icon::before {
  border-color: var(--white);
}
.date-picker-box .react-datepicker__current-month,
.date-picker-box .react-datepicker-time__header,
.date-picker-box .react-datepicker-year-header,
.date-picker-box .react-datepicker__day-name {
  color: var(--white);
}
.date-picker-box .react-datepicker__day--selected:hover,
.date-picker-box .react-datepicker__day--keyboard-selected,
.date-picker-box .react-datepicker__day--selected {
  background-color: var(--blue) !important;
}
.react-datepicker__year-read-view--selected-year {
  color: #fff;
  font-weight: 700;
}
.react-datepicker__year-read-view--down-arrow {
  position: absolute;
  top: 3px;
}
/* more info modal */
.more-info-modal {
  max-width: 570px !important;
}
.more-info-modal .modal-content {
  border-radius: 0;
  border: none;
}
.more-info-modal button.close {
  opacity: 1 !important;
}
.more-info-modal .modal-body {
  padding: 40px 60px;
  padding-top: 0px;
  padding-bottom: 10px;
}
.more-info-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}
.custom-modal-footer {
  padding: 30px 65px;
  padding-top: 0px;
}
.custom-modal-footer .pd-0 {
  padding: 10px;
}

/* skill page css */
.search-input::placeholder {
  font-size: 15px;
}
.search-input {
  width: 100%;
  padding: 15px;
  padding-left: 40px;
}
.form-group.custom-search-box {
  display: flex;
  margin-bottom: 5px;
  position: relative;
}
.search-btn {
  border: none;
  background-color: transparent;
  color: #000;
  padding: 0px 15px;
  border-radius: 4px;
  position: absolute;
  height: 100%;
}

.search-list {
  padding-left: 0px;
  list-style: none;
  box-shadow: 0 0 5px rgb(88 88 95 / 20%);
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  position: absolute;
  z-index: 9;
  width: 86%;
}
.searchlist-skill {
  overflow-y: auto;
  max-height: 250px;
}
.search-list li {
  padding: 9px 15px;
  cursor: pointer;
}
.search-list .seacrch-icon {
  font-size: 12px;
  margin-right: 10px;
}
.search-list li:hover {
  background: var(--light-bg);
}
/* preview tips css */
.prev-tips-btn {
  font-size: 13px;
  color: var(--green);
  padding-left: 25px;
  position: relative;
}
.bar-left:before {
  content: "";
  height: 10px;
  width: 1px;
  position: absolute;
  left: 12px;
  top: 4px;
  background-color: #d9d9d9;
}
/* tips popover css */
.popover-box {
  position: relative;
  display: inline;
}
.popover-box-inner {
  border-radius: 0;
  width: 370px;
  padding: 25px 25px 30px 25px;
  background-color: var(--white);
  position: absolute;
  z-index: 9;
  top: 25px;
  right: 0;
  text-align: left;
  border: 1px solid #d7dde2;
  box-shadow: 0 0 10px 2px rgb(17 17 17 / 4%);
}
.popover-box-inner:before {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  right: 8px;
  border-width: 0 9px 7px;
  border-bottom-color: var(--blue);
  top: -7px;
}

.modal-tip-body {
  font-size: 13px;
  line-height: 1.54;
}
.list-tips {
  border: 1px dashed #cba668;
  background-color: #fffef9;
  margin: 25px 0 0;
  padding: 26px 21px;
  font-size: 12px;
  padding-left: 35px;
  margin-bottom: 0px;
}

.title-tip {
  color: var(--blue);
  font-weight: 400 !important;
  font-size: 13px !important;
}
.close-tips {
  margin-bottom: 0;
  position: absolute;
  right: 15px;
  top: 0px;
  font-size: 22px;
  font-weight: 600;
}
/* search tags css */
.fiter-search-right {
  width: 100%;
}
.fiter-search-left {
  width: 28%;
}
.fiter-search {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.skill-list-box ul {
  list-style: none;
  padding-left: 0;
}

.skill-list-box ul li {
  border-top: 1px solid #d7dde2;
  padding: 12px 16px 16px;
}
.search-key-box {
  font-size: 12px;
  font-weight: 500;
}
.search-key-list {
  padding-left: 0px;
  list-style: none;
}
.search-key-list li {
  display: flex;
  color: var(--blue);
  cursor: pointer;
  margin-bottom: 18px;
}

.key-icon {
  margin-right: 5px;
}
.skill-list-editor {
  width: 100%;
  border-right: none;
  padding: 0px;
  /* border-bottom: 1px solid #d7dde2; */
}
.skill-list-box ul {
  list-style: none;
  padding-left: 0;
  /* border: 1px solid #d7dde2; */
  margin-bottom: 0px;
  border-top: none;
}
.skill-list-box ul li {
  border: 1px solid #b9b9b9;
  padding: 12px 16px 16px;
  display: flex;
  align-items: center;
  font-size: 13px;
  background: #fff;
  margin: 21px;
  width: 93%;
}
.skill-list-box ul li:first-child {
  margin-top: 0;
}
.skill-list-box ul li.transition {
  background: #f2f2f2 !important;
}
.skill-list-box ul li.transition p {
  position: relative;
  top: 10px;
}
.add-rmv-btn {
  margin-right: 15px;
}
.add-rmv-btn button {
  height: 35px;
  width: 35px;
  padding: 0px;
  border: none;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  background-color: var(--blue);
  color: #fff;
  border-radius: 4px;
}
/* extra section css */
.extra-section-preview {
  padding-top: 25%;
}
.new-section-badge {
  position: relative;
  background: var(--blue);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
  padding: 3px 8px 3px 10px;
  margin-left: 15px;
  letter-spacing: 0;
}
.new-section-badge::before,
.new-section-badge:after {
  content: "";
  position: absolute;

  right: -9px;
  border-left: 5px solid var(--blue);
  border-right: 5px solid transparent;
}
.new-section-badge::before {
  border-top: 5px solid var(--blue);
  border-bottom: 5px solid transparent;
  top: 0;
}
.new-section-badge:after {
  border-top: 5px solid transparent;
  bottom: 0;
  border-bottom: 5px solid var(--blue);
}
.check-vd-input .form-group {
  width: 100%;
}
.check-vd-input {
  display: flex;
  align-items: center;
}

.check-vd-input .checkmark {
  top: -1px !important;
}
/* final-resume */
.sidebar-link {
  /* width: 160px; */
  font-size: 15px;
  font-weight: 500;
  padding-top: 40px;
}
.sidebar-link a,
.sidebar-link button {
  color: var(--white) !important;
}
.sidebar-link a:hover,
.sidebar-link button:hover {
  text-decoration: none;
  color: var(--yellow) !important;
}
.check-link {
  border-bottom: 2px solid #e8ecf0;
  padding: 0 0 8px 0;
  margin: 0 0 8px 0;
  display: block;
}
.sidebar-title {
  font-size: 15px;
  font-weight: 600 !important;
  margin: 8px 0;
  color: white;
}
.resume-link {
  line-height: 18px;
  padding: 6px 7px;
  margin-bottom: 2px;
  width: 100%;
  display: block;
}
.resume-link .fa {
  vertical-align: top;
  font-size: 14px;
  width: 22px;
}
.list-sepration {
  border-top: 2px solid #dee2e6;
  margin-top: 12px;
  padding-top: 18px;
  display: block;
}
.resume-icon-text {
  /* display: inline-block; */
  /* width: calc(100% - 22px); */
  word-wrap: break-word;
  width: auto;
}

.form-control-tip {
  font-style: italic;
  font-size: 12px;
  font-weight: 500;
}
.modal.show .download-resume-dialog.more-info-modal {
  margin-top: 1.75rem !important;
}
.editable-text-box {
  font-size: 15px;
  position: relative;
  font-weight: 500;
}
.editable-text-box {
  font-size: 15px;
  position: relative;
  font-weight: 500;
}

.check-btn {
  border: none;
  opacity: 0.8;
  border: 1px solid #919191;
  border-radius: 4px;
}
.check-btn {
  height: 30px;
}
.change-editable-text {
  height: 30px;
  padding: 0px 10px;
}
.change-editable-text:focus,
.change-editable-text {
  border: 1px solid var(--blue) !important;
  outline: none;
  border-radius: 0px;
}
.final-resume-box {
  box-shadow: 0 2px 4px rgba(145, 158, 168, 0.3);
  background-color: #fff;
}

.main-resume {
  margin: 0px 0%;
}
.top-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.more-opt {
  font-size: 14px;
}
.more-opt i.fa {
  font-size: 11px;
  opacity: 0.8;
}
.modal-dialog.more-opt-modal {
  max-width: 442px !important;
}
.more-opt-modal .modal-body {
  padding: 40px 30px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.btn-social {
  box-shadow: 0 2px 2px rgb(0 0 0 / 24%), 0 0 2px rgb(0 0 0 / 12%);
  width: 246px;
  padding: 8px 10px;
  margin-bottom: 15px;
  display: block;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
}
.optional-or {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  text-align: center;
}
.optional-or:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  top: 7px;
  background: #d7dde2;
}
.optional-or span {
  background: #fff;
  position: relative;
  z-index: 1;
  padding: 6px;
}
.social-connect-form input.form-control {
  height: calc(2.2em + 0.75rem + 2px) !important;
}
/* footer tool bar css */

.drop-down-template {
  /* background: var(--blue); */
  color: #fff;
  padding: 12px 15px;
  position: relative;
  z-index: 99;
  cursor: pointer;
  margin-right: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  color: #000;
}
.drop-down-template.pdc {
  padding: 7px;
}
.resume-icon-box {
  background-color: #f5f5f5;
}
.drop-down-template span {
  display: inline-block;
}
.drop-down-template span i.fa {
  font-size: 9px;
  vertical-align: baseline;
  position: relative;
  bottom: 2px;
}
.drop-down-template .color-selector {
  margin-right: 0px;
}
.drop-down-template .color-selector-radio {
  height: 23px;
  width: 23px;
}
.drop-down-template .root-color .color-selector-radio {
  border: 2px solid var(--white);
  background-color: var(--white);
}
.drop-down-template .root-color .color-selector-radio:after {
  width: 15px;
  display: none;
}
.bottom-fix-toolbar .custom-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.custom-left-sec {
  display: flex;
  flex-wrap: wrap;
}
.template {
  width: auto;
}
.formate {
  width: 320px;
}
.custom-left-sec .custom-col {
  margin-right: 10px;
}
/* .bottom-fix-toolbar .container {
  padding-left: 0px;
  padding-right: 0px;
} */
.next-btn .site-btn {
  min-width: 200px;
}
.site-btn {
  min-width: 170px;
}
.bottom-fix-toolbar .slick-slider .slick-next {
  right: 0px;
}
.bottom-fix-toolbar .slick-slider .slick-prev {
  left: 0px;
}
.bottom-fix-toolbar .slick-slider .slick-prev,
.bottom-fix-toolbar .slick-slider .slick-next {
  background: var(--blue);
  height: 50px;
  width: 50px;
  z-index: 9;
}

.resume-preview {
  margin-right: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.resume-slide-box .active-slide-resume.resume-preview {
  border: 2px solid var(--blue);
}

/* resume template css start */
.resume-template-box {
  padding: 15px 2%;
  color: var(--resume-text);
}
.resume-preview .resume-template-box {
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%) !important;
}
.resume-temp {
  display: flex;
  justify-content: center;
  box-shadow: 0 0 13px 0 rgba(1, 1, 1, 0.25) !important;
  /* border: 2px solid transparent; */
  /* padding: 3px; */
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}
.resume-temp .resume-zoom .preview-inner {
  height: 432px;
  overflow: hidden;
}
.resume-temp:hover {
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 25%) !important;
  transition: all 0.3s ease-in-out;
}
.top-heading {
  padding: 15px;
  text-align: center;
}
.resume-heading {
  /* font-family: "Tinos", serif;
  font-style: italic; */
  word-break: break-word;
  font-size: 20px;
  color: var(--resume-heading);
  margin-bottom: 20px;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}
body.modal-open {
  padding-right: 0px !important;
}
.heading-resume {
  /* font-family: "Tinos", serif;
  font-style: italic; */
  font-size: 20px;
  color: var(--heading-resume);
  margin-bottom: 20px;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.resume-template-box ul {
  padding-left: 15px;
}
.percent {
  display: flex;
  justify-content: space-between;
}
.percent-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.percent-clr {
  width: 15%;
  height: 4px;
}
.clr-dark {
  color: #333;
}
.resume-icon {
  padding-right: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
}
.top-heading {
  /* font-family: "Tinos", serif; */
  font-size: 46px;
  letter-spacing: 0.8px;
  line-height: 46px;
  padding: 30px;
  padding-top: 45px;
}
.top-icon {
  display: inline-block;
  border: 1px solid;
  padding: 9px 12px !important;
  margin-bottom: 15px !important;
}
.resume-sec-temp {
  padding: 0;
}
.resume-pd-sec {
  padding: 5%;
}
.fade-bg {
  position: relative;
}
.fade-bg:after {
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  top: 0;
  position: absolute;
  z-index: 1;
}
.opacit-1 {
  z-index: 2;
  position: relative;
}
.resume-sec-temp {
  color: inherit;
}
.w-40 {
  width: 40%;
}
.resume-table td {
  vertical-align: top;
}
.Name-heading {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 25px;
}
.Name-heading:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: var(--white);
}
.resume-sec-temp .socail-info p {
  margin-bottom: 8px !important;
  padding-left: 0px;
  position: relative;
}
.socail-info p {
  position: relative;
  padding-left: 25px;
}
.resume-one-table td {
  vertical-align: top;
  text-align: left;
}
/* third resume */
.resume-table-third {
  width: 100%;
}
.resume-table-third td {
  vertical-align: top;
}
.social-info-third p {
  margin-bottom: 8px;
}
.resume-heading-third {
  font-size: 25px;
  font-weight: 500 !important;
  position: relative;
}
.resume-third-temp .resume-heading .heading-resume {
  font-weight: 500 !important;
  position: relative;
  padding-left: 18px;
  font-style: inherit;
  font-family: "Montserrat";
}
.resume-third-temp .summay-box,
.resume-third-temp .work-history-box,
.resume-third-temp .skill-list {
  margin-bottom: 35px;
  word-break: keep-all;
}
.skill-list ul li {
  word-break: keep-all;
}
.summay-box p {
  word-break: keep-all;
}
.education-list p {
  word-break: keep-all;
}
.resume-accomplish ul li p {
  word-break: keep-all;
}
.resume-addinfo p {
  word-break: keep-all;
}
.resume-affi ul li {
  word-break: keep-all;
}
.resume-cert ul li {
  word-break: keep-all;
}
.resume-workhistory p {
  word-break: keep-all;
}
.resume-third-temp .resume-heading:after .heading-resume:after {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  background: #ddd;
  height: 19px;
  width: 2px;
}
.resume-heading-third {
  margin-bottom: 20px;
}
/* resume template css end */
/* footer css */
.footer-links {
  padding-left: 0;
  list-style: none;
  text-align: center;
  margin-bottom: 0px;
}
.footer-links li {
  display: inline-block;
}
.footer-links li a {
  font-size: 11px;
  color: #333 !important;
  padding: 0 11px;
  margin: 0;
  text-transform: uppercase;
  position: relative;
  border-left: 1px solid #b7b7b7;
}
.footer-links li:first-child a {
  border-left: none;
}
.footer-bootom {
  font-size: 11px;
}
.footer-logo img {
  height: 12px;
}
.footer-top {
  position: relative;
  padding: 50px 0 20px;
  color: #000;
}
.footer {
  margin-top: -44px;
  font-weight: 500;
}
.footer-bootom {
  background-color: #fff;
  padding: 15px 0;
}
/* desktop/mob show */
.mob-show {
  display: none;
}
/* customer admin css */
.notify-link,
.user-link {
  position: relative;
  display: inline-block;
}
.notify-link:hover .notification {
  display: block;
}
.user-link:hover .user-info {
  display: block;
}
.user-link .fa.fa-caret-down {
  transition: all 0.3s ease-in-out;
}
.user-link:hover .fa.fa-caret-down {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
.notification,
.user-info {
  position: absolute;
  right: 0;
  display: none;
  z-index: 9;
}
.notification {
  width: 300px;
}
.notification ul,
.user-info ul {
  list-style: none;
  background: #fff;
  padding-left: 0;
  border: 1px solid #dee2e6 !important;
}
.notification ul li {
  padding: 10px 15px;
}
.drop-heading {
  background-color: var(--blue);
  color: var(--white);
}
.user-info ul {
  width: 215px;
}
.user-info ul li {
  padding: 5px 10px;
}
.customer-header .navbar .nav-link {
  color: #555 !important;
  font-weight: 500;
}
.nav-pd {
  padding-right: 15px;
}
.customer-header .nav-link .fa {
  font-size: 18px;
}
.customer-header .navbar {
  padding: 0;
}
.signin-logo img {
  height: 55px;
}
.customer-res-link.mr-auto .nav-item.active .nav-link {
  padding: 20px 0px;
  position: relative;
}
.customer-header .navbar-brand {
  margin-right: 5rem;
}
.customer-res-link.mr-auto .nav-item.active .nav-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background-color: var(--blue);
}
.customer-res-link.mr-auto .nav-item .nav-link {
  text-transform: uppercase;
}
.create-resume-link {
  background-color: var(--blue) !important;
  color: var(--white) !important;
  border-radius: 0 !important;
}
.resume-view-list .nav-tabs {
  border-bottom: none;
  justify-content: end;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
}
.resume-view-list .nav-tabs .nav-link {
  border-radius: 0px;
  padding: 4px 13px;
  border: 1px solid #ccc;
  margin-right: -1px;
}

input#session-date {
  position: relative;
  overflow: hidden;
}
input#session-date::-webkit-calendar-picker-indicator {
  display: block;
  top: 0;
  left: 0;
  background: #0000;
  position: absolute;
  transform: scale(100);
}
.resume-view-list .nav-tabs .nav-link.active {
  background: var(--blue);
  color: #fff !important;
  border: 1px solid var(--blue);
}
.resume-list-tool {
  display: flex;
  justify-content: flex-end;
}
.list-tool a span,
.list-tool span {
  display: block;
  text-align: center;
  margin-left: 15px;
  font-size: 14px;
  color: var(--dark);
}
.list-tool a span i.fa,
.list-tool span i.fa {
  font-size: 18px;
  color: var(--blue);
}
.list-view-tesume tbody tr {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem 2px;
  box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
}
.list-view-tesume tbody td {
  padding: 1rem 11px;
}
.list-view-tesume thead th,
.list-view-tesume td {
  padding: 0rem 11px;
  padding-top: 0.5rem;
}
.list-view-tesume {
  border-spacing: 0 20px;
  border-collapse: separate;
}
.doc-Strength-label {
  color: var(--blue);
  font-size: 12px;
  font-weight: 600;
}
.doc-strength-value {
  font-weight: 600;
  border-radius: 30px;
  width: 35px;
  height: 20px;
  margin-left: 5px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  font-size: 10px;
  line-height: 20px;
}
.feature-thumbnail {
  position: relative;
  height: 284px;
  width: 100%;
  padding: 0;
  border: 1px solid rgba(217, 217, 217, 0.76);
  background-color: #fff;
}
.thumb-img {
  margin: auto;
  display: block;
  overflow: hidden;
  min-height: 190px;
  max-height: 250px;
  max-width: 100%;
  padding: 10px 0;
  width: auto;
  object-fit: contain;
}
.feature-thumb-bottom {
  position: absolute;
  border-top: 1px solid rgba(217, 217, 217, 0.76);
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: none;
}
.resume-info-container {
  padding: 7px;
  box-shadow: 0 -2px 4px rgb(0 0 0 / 7%);
}
.resume-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.grid-tool {
  display: table;
  width: 100%;
  border-top: 1px solid #ccc;
}
.grid-tool .list-tool {
  display: table-cell;
  padding: 5px 2px;
  border-right: 1px solid #ccc;
}
.grid-tool .list-tool a span {
  margin-left: 0px;
  font-size: 12px;
}
.grid-tool .list-tool:last-child {
  border-right: none;
}
.resume-info-container h6 {
  font-size: 18px;
  font-weight: 600 !important;
  white-space: break-spaces;
  word-break: break-all;
}
.tab-content .fade {
  transition: none;
}
.account-content-box {
  min-height: 50vh;
  border-left: 1px solid #ccc;
  padding-left: 3rem;
}
.account-info-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.account-info-list li {
  border-top: 1px solid #cfcfcf;
  padding-left: 0;
  padding-top: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: auto;
  display: block;
}
.account-info-list li .account-info-item {
  min-width: 14rem;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
}
.account-info-list li .account-info {
  width: calc(100% - 318px);
  display: inline-block;
}
.account-info-list li .link {
  text-decoration: none;
  width: 5rem;
  vertical-align: top;
  display: inline-block;
  text-align: right;
  font-size: 14px;
}
.account-info-list li .link .hidden-sm-down {
  margin-left: 5px;
}
.tab-list-content a {
  margin-bottom: 1rem;
  color: var(--dark) !important;
}
.tab-list-content a.active {
  color: var(--blue) !important;
}
.account-email {
  max-width: 400px !important;
}
.customer-modal-edit .form-group label {
  font-size: 12px;
}
.customer-modal-edit .form-group label {
  font-size: 12px;
}
.subscription-box {
  background-color: #f4f5fb;
  padding: 3.5rem 3rem;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
}
.subscription-box h2 {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px;
  margin-bottom: 0.5rem;
}
.subscription-box p {
  margin-bottom: 0;
}
.contactus-info {
  border-left: 1px solid #9b9b9b;
  padding: 0 3rem 0 3rem;
}
.communication-check-list .checkmark {
  top: 2px;
}

button.kep-login-facebook.metro,
.kep-login-google.metro {
  padding: 12px 5px;
  width: 100%;
  font-size: 12px;
}
/* upgrade plan price css */
.payment-container .custom-check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.payment-container .h2 {
  border-bottom: 1px solid #c1c3c5;
  color: #585860;
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  margin: 3px auto 15px;
  padding: 0;
  position: relative;
  /* text-align: center; */
  /* width: 250px; */
  padding-bottom: 15px;
}
.plan-box.left-section {
  padding: 25px 16px 24px 19px;
  /* border: 1px solid #d9d9d9;
  background: #fbfcfd; */
  display: inline-block;
}
.payment-box {
  width: 90%;
  margin: auto;
  margin-top: 5%;
}
.plan-box.left-section.middle-section {
  /* box-shadow: 1px 1px 20px 0 rgb(0 0 0 / 20%); */
  padding: 0px 15px;
  border: 0;
  /* background: #fff; */
  width: 37.5%;
  display: inline-block;
}
.plan-box.left-section.middle-section.bg-one {
  min-height: 500px;
}
.plan-box.left-section.middle-section.bg-change {
  min-height: 380px;
}

.plan-box.left-section.sm-sec {
  min-height: 351px;
  width: 31.2%;
  position: relative;
  z-index: 1;
  opacity: 0.8;
}
.payment-container {
  display: flex;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.plan-box.left-section ul {
  font-size: 14px;
  line-height: 1.8;
}
/* upload image template four css */
input.img-upload-input {
  position: absolute;
  z-index: 3;
  height: 90% !important;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dummy-img {
  position: relative;
  overflow: hidden;
  /* background-image: url(../assets/dummy-profile-pic.png); */
}
/* .dummy-img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url(../assets/dummy-profile-pic.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
} */
.after-devider {
  position: relative;
}
.resume-zoom .after-devider {
  height: 4px;
  width: 20%;
}
.resume-zoom .resume-profile-img {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
}
.after-devider {
  height: 6px;
  width: 25%;
}
.resume-zoom .resume-template-box table {
  text-align: initial;
}
.photo-tips-wrap h3 {
  font-size: 16px;
  font-weight: 700;
  color: #4b494f;
  padding-bottom: 10px;
  line-height: 1.3;
  font-weight: 600 !important;
}
.profile-img-box {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 25px;
}
.profile-img-box .upload-photo-btn {
  display: inline-block !important;
}
.preview-pd .resume-preview {
  margin: 0px;
}
.preview-pd .resume-preview .resume-zoom {
  padding-left: 0px !important;
}
.profile-img-resume-contact {
  display: flex;
}
/* .resume-contact-form .col-lg-6,
.resume-contact-form .col-lg-3,
.pd-6 {
  padding: 0px 6px !important;
  padding-left: 6px !important;
} */
.profile-img-dialog {
  max-width: 775px !important;
}
.profile-img-dialog .modal-content {
  height: auto;
}
.upload-img-input {
  text-align: center;
  background: #efefef;
  border: 1px solid #ddd;
  min-height: 308px;
  position: relative;
  width: 317px;
  height: 317px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-img-link {
  position: relative;
  display: inline-block;
}
.upload-img-link .img-upload-input {
  left: 0;
}
.upload-img-link .fa {
  color: #e5e5e5;
  font-size: 40px;
}
/* formating css start */
.formating-box {
  position: absolute;
  top: 90%;
  z-index: 999;
  width: 100%;
  left: -15px;
}
.formating-box-inner {
  display: flex;
  background: #fff;
}
.format-sm,
.format-normal,
.format-large,
.format-custom {
  text-align: center;
  width: 33.3%;
  padding-top: 6px;
  border-right: 1px solid var(--blue);
}
.format-custom {
  border-right: none;
}
.formating-box-inner {
  display: flex;
  border: 1px solid var(--blue);
  width: 320px;
  margin: auto;
}
.formating-box-inner .fa {
  color: var(--blue);
}
.format-normal i.fa.fa-bars {
  font-size: 18px;
}
.format-large i.fa.fa-bars,
.format-custom i.fa.fa-cogs {
  font-size: 22px;
}
/* extra sections */
.portfolio-link .form-control {
  outline: none !important;
  box-shadow: none !important;
}
.form-control:focus {
  border: 1px solid var(--blue) !important;
  border-color: var(--blue) !important;
}
.language-search-list {
  max-height: 200px;
  overflow-y: auto;
  width: 79%;
}
.search-pd .search-input {
  padding-left: 15px;
}

.language-search-list::-webkit-scrollbar-track,
.searchlist-skill::-webkit-scrollbar-track {
  background-color: #fff;
}
.language-search-list::-webkit-scrollbar,
.searchlist-skill::-webkit-scrollbar {
  width: 7px;
  background-color: #fff;
}
.language-search-list::-webkit-scrollbar-thumb,
.searchlist-skill::-webkit-scrollbar-thumb {
  background-color: var(--light-grey);
  border-radius: 0px;
}
.language-search-list li {
  padding: 5px 10px;
  border-top: 1px solid #e5ebf2;
}
.language-slider-list {
  list-style: none;
  padding-left: 0px;
  display: flex;
  /* align-items: end; */
  justify-content: center;
}
.language-slider-list li {
  display: flex;
  width: 105px;
  font-size: 12px;
  justify-content: flex-end;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.language-slider-list li:first-child .range-slide {
  width: 10px;
}
.range-slide {
  width: 100%;
  height: 4px;
  background-color: #e8ecf0;
  margin-left: auto;
  margin-top: 15px;
  position: relative;
}
/* .language-slider-list li:last-child .range-slide {
  margin-right: auto;
  margin-left: 0%;
} */
.lang-range .range-slide:before {
  content: "";
  height: 4px;
  width: 4px;
  right: 0;
  top: 0;
  background: #ccc;
  position: absolute;
}
.language-slider-list li:first-child .range-slide:before {
  left: 0%;
}
/* .language-slider-list li:last-child .range-slide:before {
  right: 0%;
  left: auto;
} */
.language-slider-list li:first-child,
.language-slider-list li:last-child {
  width: auto;
}
.range-name {
  width: 20px;
  height: 20px;
  display: inline-block;
  box-shadow: 0 3px 8px rgb(0 0 0 / 14%);
  border: 1px solid #d7dde2;
  line-height: 20px;
  position: absolute;
  right: 0;
  top: -7px;
  z-index: 99;
  border-radius: 50%;
  background: #fff;
  font-size: 9px;
  font-weight: 600;
}
.language-slider-list li:first-child .range-name {
  right: 0;
}
/* language slider css */
.lang-range-slider {
  width: 93%;
  margin-left: auto;
  margin-right: 2%;
}
.proficency-range .MuiSlider-root {
  color: var(--blue) !important;
}
.proficency-range .MuiSlider-rail {
  height: 4px !important;
}
.proficency-range .MuiSlider-track {
  height: 4px !important;
}
.proficency-range .MuiSlider-mark {
  width: 3px !important;
  height: 4px !important;
}
.proficency-range .MuiSlider-markLabel {
  top: -35px !important;
}
.range-text {
  position: relative;
}
.MuiSlider-markLabel {
  font-size: 10px !important;
  font-weight: 600 !important;
}
.range-text .label-name {
  text-align: center;
  display: block;
}
.range-text .label-value {
  position: absolute;
  left: 20px;
  display: block;
  top: 60px;
}
/* format slider */
.font-slider {
  display: flex;
}
.font-size-value {
  display: inline-block;
  margin-right: 20px;
  color: var(--blue);
  font-weight: 500;
}
.slider-title {
  display: inline-block;
}
.font-slider .MuiSlider-mark {
  width: 2px;
  height: 3px !important;
}
.font-slider .MuiSlider-rail {
  height: 3px !important;
}
.font-slider .MuiSlider-colorPrimary {
  color: var(--blue) !important;
}
.row-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.row-flex .cus-col:last-child {
  width: 60%;
}
/* final resume hover effect */
.format-icon button {
  display: none;
}
.final-resume-box .format-icon {
  display: none;
}
.final-resume-box .format-icon button {
  border: none;
  background-color: transparent;
  color: var(--dark);
  font-size: 15px;
}
.final-resume-box .format-icon button i.fa {
  font-size: 18px;
}
.final-temp-icon:hover .format-icon.format-right button,
.final-temp-icon:focus .format-icon.format-right button {
  color: var(--dark);
}
.final-resume-box .final-temp-icon:hover .format-icon button,
.final-resume-box .final-temp-icon:focus .format-icon button {
  display: block;
}
.final-temp-icon {
  position: relative;
  z-index: 3;
}
.final-temp-icon:hover,
.final-temp-icon:focus {
  z-index: 1;
}
.signature-col:hover .final-temp-icon:focus:after,
.signature-col:hover .final-temp-icon:hover:after {
  display: block;
}
.final-temp-icon:hover:after,
.final-temp-icon:focus:after {
  content: "";
  position: absolute;
  background-color: transparent;
  left: -4px;
  top: 0;
  height: 100%;
  z-index: 2;
  width: 102%;
  border: 4px solid orange;
  box-shadow: 0 2px 4px rgba(180, 201, 218, 0.5);
}
.final-temp-icon:hover .format-icon,
.final-temp-icon:focus .format-icon {
  position: absolute;
  height: 120%;
  background: #f6f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: -10%;
  width: 10%;
  border: 1px solid #ccc;
}
.final-temp-icon:hover .format-icon.format-right,
.final-temp-icon:focus .format-icon.format-right {
  right: -2% !important;
  background: transparent;
  border: none;
  background: orange;
  width: auto;
  height: 100%;
  top: 0;
  position: absolute !important;
}
.final-temp-icon:hover .format-icon.format-left,
.final-temp-icon:focus .format-icon.format-left {
  /* left: -20% !important; */
  right: 20%;
  height: auto;
  flex-direction: inherit;
  top: -23px;
  background: transparent;
  border: none;
  position: absolute !important;
}
.format-icon.format-left button {
  background: orange;
  margin-left: 10px;
  border-radius: 10px 10px 0px 0px;
}
.parent-col:nth-of-type(2) .final-temp-icon:hover:after {
  width: 102%;
}
.parent-col:nth-of-type(2) .final-temp-icon:hover .format-icon {
  width: auto;
}
.parent-col:nth-of-type(2) .final-temp-icon:hover:after {
  left: -2% !important;
}
.parent-col:nth-of-type(2) .final-temp-icon:hover .format-icon.format-left {
  right: 20% !important;
}
.parent-col:nth-of-type(2) .final-temp-icon:hover .format-icon.format-right {
  right: -12% !important;
}
.resume-content-one {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.resume-content-three .parent-col:nth-of-type(2) .final-temp-icon:hover:after {
  width: 100%;
}
.resume-content-three .parent-col:nth-of-type(2) .final-temp-icon:hover:after {
  left: 0% !important;
}
.resume-content-three
  .parent-col:nth-of-type(2)
  .final-temp-icon:hover
  .format-icon {
  width: auto;
  z-index: 3;
}
.resume-content-three
  .parent-col:nth-of-type(2)
  .final-temp-icon:hover
  .format-icon.format-left {
  right: 20% !important;
}
.resume-content-three
  .parent-col:nth-of-type(2)
  .final-temp-icon:hover
  .format-icon.format-right {
  right: -2% !important;
}
/* template 3 */
.temp-3 .final-temp-icon:hover:after {
  width: 140%;
}
.temp-3 .final-temp-icon:hover .format-icon {
  width: 7%;
}
.temp-3 .final-temp-icon:hover:after {
  left: -15%;
  width: 125%;
}
.temp-3 .final-temp-icon:hover .format-icon.format-left {
  right: 20% !important;
}
.temp-3 .final-temp-icon:hover .format-icon.format-right {
  right: -10% !important;
}
.temp-3 .final-temp-icon:hover .resume-heading:before .heading-resume:before {
  z-index: -2;
}
/* resume slider */
.resume-slide-box .slick-slide {
  width: 240px !important;
}
.resume-slide-box .resume-preview .resume-zoom .preview-inner {
  height: 225px;
}
.resume-slide-box .resume-zoom {
  cursor: pointer;
}
.resume-slide-box .resume-preview .resume-zoom .resume-template-box {
  font-size: 5.5px;
}
.resume-preview .resume-zoom .resume-template-box {
  background-color: #fff;
}
.resume-slide-box .resume-content-box .parent-col {
  min-height: 170px;
}
.resume-slide-box .resume-zoom .name-title .title-name {
  font-size: 7px !important;
}
.resume-slide-box .resume-zoom .top-heading {
  padding: 2px;
  font-size: 8px;
  line-height: 0px;
  padding-top: 10px;
}
/* recommanded section */
.recom-loder {
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog-recom {
  max-width: 636px !important;
}
.modal-content-box .modal-body {
  padding: 1rem 3.5rem;
}
.list-ttc-recommend {
  background-color: #f6f8fa;
  padding: 28px;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  padding-left: 55px;
}
.temp-4-pd {
  padding: 0px !important;
}
/* addition section */
.add-other-language button {
  border: none;
  background-color: transparent;
  padding: 0px;
}
.test {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}
.btn-outline-danger {
  color: var(--blue);
  border-color: #dc3545;
  border: none;
  font-size: 18px;
}
.test .search-input {
  width: 84.5%;
  min-width: 255px;
}
.active-slide + .hide-row {
  display: none;
}
.active-slide .lang-range-slider {
  width: 92%;
  margin: auto;
}
.active-slide .range-text .label-value {
  position: absolute;
  left: 20px !important;
  display: block;
  top: 60px;
}
.active-slide .paragroup-item {
  padding: 20px 60px 0 40px;
}
.active-slide .paragroup-item.pd-custom {
  padding: 15px 20px 0px 20px;
}
.active-slide .paragroup-item.pd-custom .para-toolbar {
  top: 15px;
  background: #000;
  color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.text-base {
  font-size: 15px;
}
.number-text {
  font-size: 19px;
  font-weight: 500;
}
.langg-hd {
  margin-bottom: 1.4rem;
}
.active-slide .education-del-para {
  font-size: 10px;
}
.active-slide .label-name,
.active-slide .label-value {
  font-size: 10px;
}
.active-slide .label-name {
  top: 15px;
  position: relative;
}
.active-slide .para-toolbar {
  top: 40px;
}
/* .active-slide {
  margin-bottom: 50px;
} */
.reactEasyCrop_Image {
  width: 100% !important;
}
.transition {
  background: linear-gradient(to right, #f6f8fa 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  animation: makeItfadeIn 0.3s;
  animation-fill-mode: forwards;
  position: relative;
  width: 100%;
}
.transition:before {
  content: "Empfohlen";
  position: absolute;
  top: 5px;
  left: 66px;
  color: #f2c900;
  animation: slide-in-out 0.8s;
  opacity: 1;
}
@keyframes slide-in-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.transition:after {
  background: var(--green);
  content: "+";
  position: absolute;
  left: 15px;
  top: 12px;
  width: 40px;
  height: 40px;
  color: #fff;
  font-size: 18px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: slide-in 0.8s;
  opacity: 0;
}
@keyframes slide-in {
  0% {
    left: 0px;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}
@keyframes makeItfadeIn {
  100% {
    background-position: left bottom;
    background: linear-gradient(to right, #f6f8fa 100%, #f6f8fa 0%);
  }
}
/* resume pdf css */
/* .preview-inner{
	background-color:#fff;
} */
/* .resume-content-one .parent-col:nth-of-type(1) {
    padding-left: 15px;
} */
.final-resume-box .temp-4-pd {
  border-width: 18px !important;
}
/* social-icon css */
.resume-icon svg {
  width: 14px;
  height: 14px;
}
.slick-slider .resume-icon svg {
  width: 6px;
  height: 6px;
}
.slick-slider .resume-content-one .parent-col:nth-of-type(2) {
  padding-right: 8px;
  padding-left: 10px;
}

.slick-slider .resume-content-one .parent-col:nth-of-type(1) {
  padding-top: 0px !important;
}
.final-resume-box .resume-icon svg {
  height: 12px;
  width: 12px;
}
* {
  page-break-inside: avoid !important;
}
.d-grid {
  display: grid;
  grid-template-columns: 50% 50%;
}
.d-grid-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.resume-content-box {
  display: flex;
  justify-content: flex-start !important;
  height: 100%;
  /* overflow: hidden; */
  padding-left: 25px;
  padding-right: 25px;
  flex-wrap: wrap;
}
.resume-content-one .parent-col:nth-of-type(1),
.temp-4-pd .parent-col:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  min-height: auto !important;
  padding-right: 0px !important;
}
.resume-content-one .parent-col:nth-of-type(2),
.temp-4-pd .parent-col:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  width: 34% !important;
}
.resume-content-one .parent-col:nth-of-type(3),
.temp-4-pd .parent-col:nth-of-type(3) {
  width: 66%;
  padding-left: 15px;
  padding-right: 15px;
}
.resume-content-one .parent-col:nth-of-type(3),
.resume-content-one .parent-col:nth-of-type(2),
.temp-4-pd .parent-col:nth-of-type(3),
.temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 10px !important;
}
.resume-content-one .parent-col:nth-of-type(2),
.temp-4-pd .parent-col:nth-of-type(2) {
  background-color: #fff;
}
/* .parent-col div[data-rbd-droppable-id="column1"], .parent-col div[data-rbd-droppable-id="column2"]{
  page-break-after: always;
  page-break-before: always;
}
.parent-col div{
  word-break: break-all;
} */
.resume-template-box {
  word-break: break-all;
}
.top-section.parent-container {
  padding-left: 0px;
  padding-right: 0px;
}
.slick-slider .resume-content-one .parent-col:nth-of-type(3),
.slick-slider .temp-4-pd .parent-col:nth-of-type(3) {
  padding-left: 10px;
  padding-right: 10px;
}
.temp-4-pd .parent-col:nth-of-type(2) {
  padding-right: 15px;
  border-left: none;
}
.resume-content-one .parent-col:nth-of-type(3) {
  border-left: none;
  background-color: #fafafa;
}
.resume-content-one .parent-col:nth-of-type(2) {
  padding-right: 15px;
  padding-left: 15px;
}
.resume-content-three .parent-col:nth-of-type(2) {
  width: 100% !important;
  padding: 20px;
}
.resume-preview-box .resume-template-box {
  padding: 0px !important;
  padding-top: 0 !important;
}
.resume-preview-box
  .resume-template-box
  .resume-zoom
  .temp-3
  .resume-content-three {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.resume-preview-box .resume-content-three {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  padding-top: 4px !important;
}
.resume-preview-box .resume-content-three .parent-col:nth-of-type(2) {
  border-left: 1px solid #ccc;
}
.resume-preview-box .top-section.parent-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.resume-preview-box .resume-icon svg {
  width: 7px;
  height: 7px;
}
.resume-preview-box .resume-content-three .parent-col:nth-of-type(2) {
  padding-top: 0px !important;
  padding-left: 10px !important;
}
.temp-4-pd .resume-content-box {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.resume-content-three .parent-col:nth-of-type(2) {
  padding-top: 20px !important;
}
.resume-zoom.preview-box .temp-4-pd .resume-content-box {
  margin: 10px;
}
.temp-4-pd .resume-content-box {
  margin: 20px;
}
.resume-preview-box .temp-4-pd .resume-content-box {
  margin: 0px;
}
.slick-slider .resume-template-box .resume-content-three {
  margin: 0px !important;
}
.slick-slider .temp-4-pd .resume-content-box {
  margin: 8px !important;
}
.slick-slider .temp-4-pd .resume-content-box .parent-col:nth-of-type(1) {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
/* accessbility page css start */
.access-top-sec {
  background-color: #e9eefd;
  padding: 6% 0px;
}
.table-top {
  position: relative;
  padding: 10px 15px;
  padding-right: 32px;
}
.table-top .icon-img {
  position: absolute;
  right: 12px;
}
.table-content {
  padding: 12px;
  margin-top: -1px;
}
.table-content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0px;
}
.table-content ul a {
  padding: 10px 30px;
  display: block;
}
.table-content ul a:focus {
  outline: 2px dashed var(--blue);
  outline-offset: 4px;
}
.access-from label {
  display: block;
}
.access-from .form-group input {
  width: 100%;
  padding: 10px;
}
.access-list,
.editor-content p {
  line-height: 2;
}
textarea.form-control {
  border: 1px solid var(--light-dark) !important;
  box-shadow: none !important;
}
.access-sub {
  min-width: 200px;
}
.temp-size {
  width: auto;
  height: 100vh;
}
/* murlidhar css end */
.ant-picker-input input,
.ant-picker-input input:focus {
  border: none !important;
  outline: none !important;
}

.date-picker-box .ant-space.ant-space-vertical {
  width: 100%;
}
.ant-picker-input {
  top: -4px;
  height: 2rem;
}
.ant-picker.form-control {
  border: 1px solid var(--light-dark) !important;
  box-shadow: none !important;
}
.ant-picker.form-control:focus,
.ant-picker.form-control:active,
.ant-picker.form-control:visited {
  border: 1px solid var(--blue) !important;
}
.text-german {
  align-items: center;
  display: flex;
}
.chosse-page-title {
  margin-bottom: 29px;
  font-size: 40px;
  margin-top: 6px;
}
.fa-check:before {
  content: "\f00c";
  position: relative;
  top: 0px;
}
.sepration-bar {
  display: flex;
  align-items: center;
}
p {
  word-wrap: break-word;
}
.strip-pay iframe {
  height: 100% !important;
}
.paymentForm {
  width: 100% !important;
  height: 100%;
}
.paymentForm .paymentFormBtn,
.paymentForm .paymentFormBtn:hover {
  border: none !important;
  background-color: var(--blue) !important;
}
.side-content {
  position: fixed;
  top: 0;
  right: -100%;
  width: 33%;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  z-index: 9;
  background-color: #fafafa;
  transition: all 0.3s ease-in-out;
}
.paymentForm {
  padding: 25px;
}
.side-content.sidebaropen {
  transition: all 0.3s ease-in-out;
  right: 0;
}
.overlay:after {
  position: absolute;
  content: "";
  left: 0;
  top: -72px;
  height: 125%;
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
}
.overlay {
  position: relative;
  transition: all 0.1s ease-in-out;
}
.strip-pay iframe {
  height: 100% !important;
}
.paymentForm {
  width: 100% !important;
  height: 100%;
}
.paymentForm .paymentFormBtn,
.paymentForm .paymentFormBtn:hover {
  border: none !important;
  background-color: var(--blue) !important;
}
.slide-pane_from_right.content-after-open {
  transform: translateX(0%);
  width: 30% !important;
  transition: all 0.1s ease-in-out;
}
.price-box {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  padding: 18px;
  display: block !important;
  text-align: center;
}
.text-4xl {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
}
.text-gray-400 {
  color: #999;
}
.paymentForm > p {
  width: 100% !important;
  color: rgba(0, 0, 0, 0.753);
  border-bottom: none;
  padding: 1vmax 0;
  text-align: center;
}
.header-hd {
  background-color: var(--blue);
  color: #fff;
  text-align: center;
  padding: 5px 0px;
}
.times-icon {
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
}
.template-section .resume-preview-box .resume-temp {
  min-height: 467px;
  padding: 5px;
}
.template-section .resume-preview-box .preview-inner {
  height: 436px;
}
.no-data {
  text-align: center;
  color: #d78c2a;
  font-size: 22px;
  font-weight: 500;
  padding: 30px;
}
.text-3xl {
  font-size: 2rem;
  font-weight: 600 !important;
}
.resume-zoom h1.name-title,
.resume-zoom .name-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.temp-4-pd .skill-list.resume-skill.pt-5.pl-3.pr-3,
.resume-home-temp .skill-list.resume-skill.pt-5.pl-3.pr-3,
.temp-4-pd .educationclass.resume-education.pl-3.pr-3,
.resume-home-temp .educationclass.resume-education.pl-3.pr-3 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.resume-temp .resume-select-btn {
  top: 50%;
  position: absolute;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  min-width: 220px;
}
.resume-temp:hover .resume-select-btn {
  opacity: 1 !important;
  z-index: 10;
}
.resume-temp {
  position: relative;
}
.resume-temp:hover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 9;
}
.page-title-big {
  font-size: 2rem;
  font-weight: 600 !important;
}
.resume-template-box.temp-3 .socail-info.resume-social-info h1.resume-heading {
  color: #fff;
}
.resume-zoom .resume-template-box.temp-3 .socail-info.resume-social-info p {
  font-size: 10px;
}
.resume-zoom .top-heading {
  min-height: 125px;
}
.bg-double {
  position: relative;
}
.bg-double:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: #f2fafb;
  height: 100%;
  width: 48%;
  z-index: -1;
}
.bg-double.resume-form-page:after {
  width: 43.5%;
}
.bg-green {
  background: #27616e;
}
.skill-list-box-outer {
  border: 1px solid #b9b9b9;
  margin-top: -1px;
  background: #f2f2f2;
}
.resume-temp .resume-zoom .resume-template-box .preview-inner {
  height: 100% !important;
}
.width-92 {
  width: 92%;
}
.date-flex {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.date-flex select#month-dropdown {
  width: 100%;
}
/* final resume */
.fixed-header {
  position: fixed;
  width: 100%;
  z-index: 9;
  background: #000;
}
.fixed-header .header {
  background: transparent;
}
.final-resume-container {
  padding-bottom: 90px;
  padding-top: 144px;
}
.bottom-fix-toolbar {
  position: fixed;
  top: 66px;
  z-index: 9;
  width: 100%;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.22);
  background-color: #fff;
  padding-bottom: 0px;
}
.resume-slide-box {
  background: #f5f7f8;
  border-left: 1px solid #f5f7f8;
  border-radius: 0;
  height: calc(100vh - 129.1px);
  position: fixed;
  right: -305px;
  overflow: hidden;
  top: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  width: 305px;
  margin-top: 134px;
  padding: 5px;
  padding-top: 15px;
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.4s ease-in-out;
}
.resume-slide-box.active {
  transition: all 0.4s ease-in-out;
  right: 0;
}
.more-color-dropdown {
  position: absolute;
  top: 63%;
  right: 0;
}
.more-color-dropdown:after {
  content: "";
  position: absolute;
  right: -5px;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
  transform: translate(-50%, -50%);
}
.format-section {
  justify-content: flex-end;
  flex-direction: revert;
  min-width: 500px;
  border-left: 1px solid #ccc;
  margin-top: 5px;
  padding-left: 19px !important;
}
.resume-contact-form label {
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
  font-size: 10px !important;
}
.error-text {
  position: absolute;
  top: 100%;
  font-size: 10px;
}

.final-resume-box {
  color: #000;
}
div#socialInfo p {
  min-height: 10px;
}
.marital-status-icon {
  padding-left: 1px;
}
.marital-icon img {
  border: none;
  height: 8px;
}
p.para-toolbar {
  display: flex;
}

p.para-toolbar button {
  background: #000;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: revert;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

p.para-toolbar button i.fa {
  font-size: 12px;
  color: #fff;
}

.skill-editor .ck-editor__editable_inline {
  min-height: 380px;
}
.temp-4 {
  color: #000;
}
/* search input */
/* .langg-input {
    padding: 9px 15px;
    background: #fff;
    border: 1px solid var(--light-dark) !important;
    width: 84.5%;
    text-align: left;
    font-size: 14px !important;
} */
.langg-box-list {
  margin-top: 15px;
}
.langg-box-list:first-child {
  margin-top: 0px;
}
.portfolio-link .active-slide {
  margin-bottom: 15px;
}
input.form-control,
.form-control:focus {
  color: #000 !important;
  font-weight: 500 !important;
}
.collapse-icon .fa {
  transition: all 0.3s ease-in-out;
}
.text-blue.bold.collapsed .collapse-icon .fa {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}
.tab-content .deactive {
  display: none;
}
.tab-content .active {
  display: block;
}
.tab-header button {
  color: #000;
  font-size: 16px;
  display: inline-block;
  position: relative;
  background-color: transparent;
  font-weight: 400;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
  padding-bottom: 7px;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
}
.tab-header button:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: transparent;
  bottom: 4px;
}
.tab-header button.active:before {
  background-color: #27616e;
}
.tab-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.customSelect,
.customSelect-outer {
  position: relative;
}
.customSelect i.fa {
  position: absolute;
  top: 10px;
  right: 10px;
}
.customSelectList {
  position: absolute;
  overflow-y: auto;
  cursor: pointer;
  height: 110px;
  background: #fff;
  z-index: 100;
  padding: 0;
  border: 1px solid #ccc;
  left: 0;
  width: 100%;
  top: 100%;
  z-index: 9;
}
.customSelectList li {
  padding: 5px 5px 5px 18px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #58585f;
  white-space: nowrap;
  font-size: 22px;
}
.customSelectList li:hover {
  background-color: #f6f8fa;
}
.customSelectList li.active:hover,
.customSelectList li.active {
  background-color: #46b8e7;
  color: #fff !important;
}
.check-item-box .checkmark {
  top: 1px !important;
  height: 20px !important;
  width: 20px !important;
}
.check-item-box .checkmark:after {
  left: 7px !important;
  top: 3px !important;
}
.disable-signature-field .form-control:disabled,
.disable-signature-field .form-control[readonly] {
  cursor: not-allowed;
}
.text-formater-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.uploadsign-color-list .color-selector-radio {
  display: flex;
  justify-content: center;
  align-items: center;
}
.font-formating .fa-xl {
  font-size: 26px;
}
.text-formater-box .font-formating-text,
.text-formater-box .format-icon {
  padding: 0 !important;
  outline: 0;
  border: none;
  background: transparent;
}
.format-icon {
  margin-right: 5px;
}
.font-formating-text {
  margin-left: 5px;
}
.format-icon svg {
  height: 25px;
  width: 25px;
}
.sign-draw {
  min-height: 85px;
  position: relative;
}
.sign-draw button {
  padding: 0;
  font-size: 10px;
  border: none;
  background: transparent;
  outline: none;
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.upload-sign-box .form-group {
  margin-bottom: 22px;
}
.upload-sign-box .fileInput {
  position: relative;
  width: 100%;
}
.upload-sign-box .fileInput input[type="file"] {
  opacity: 1;
}
.upload-sign-box .fileInput input[type="file"]::-webkit-file-upload-button{
  display: none;
}
.upload-sign-box .fileInput input[type="file"] {
    opacity: 1;
    width: calc(100% - 170px);
    border: none;
    margin-bottom: 0px;
    position: relative;
    top: 5px;
}
.upload-sign-box .customFileInput {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: space-between;
}
.upload-sign-box #uploadBtn {
  height: 45px;
  font-size: 12px !important;
  letter-spacing: unset;
  font-weight: 400;
  background-color: #1d1d1d;
  border: 1px solid #1d1d1d;
  padding-left: 14px !important;
  padding-right: 14px !important;
  line-height: 2.5;
}
.customFileInput .input-type {
  width: calC(100% - 160px);
  height: 45px;
  margin-right: 10px;
  color: #333;
  border: 1px solid #d7dde2;
  font-size: 1.4rem;
  font-family: "Source Sans Pro", Arial, sans-serif;
  border-radius: 0;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.contrast-slider {
  min-width: 150px;
}
.active-formate svg {
  fill: #006ad0;
}
.resume-template-box {
  position: relative;
}
.signature-col {
  position: absolute;
  bottom: 20px;
  width: 97%;
  padding-right: 20px;
}
.upload-sign {
  display: flex;
  flex-direction: column;
  line-height: 18px;
}
.upload-sign-preview .parent-col:last-child {
  position: relative;
  padding-bottom: 100px;
  min-height: 58%;
}
.upload-sign.text-center img {
  margin: auto;
}
.access-top-sec.text-left img {
  margin-right: auto;
}
.upload-sign.text-left img {
  margin-right: auto;
}
.upload-sign.text-right img {
  margin-left: auto;
}
.upload-sign.text-right img,
.upload-sign.text-left img,
.upload-sign.text-center img {
  max-height: 60px;
}
.upload-sign img,
.upload-sign img:hover {
  border: none;
  cursor: auto;
}
.parent-col {
  position: relative;
}
.final-temp-sign::after {
  height: 149% !important;
  top: -20% !important;
  width: 120% !important;
  left: -10% !important;
}
.final-temp-sign:hover .format-icon,
.final-temp-sign:focus .format-icon {
  height: 140%;
  top: -16%;
}
.modal-image-btn .btn {
  max-width: 140px !important;
  min-width: 140px;
}
/* template slider css start */
.scroll-resume-box .styleTempOne .parent-col:nth-of-type(2) {
  padding-top: 5px !important;
}
.scroll-resume-box .parent-col:nth-of-type(1) .styled-bg {
  padding: 3px !important;
}
.scroll-resume-box .resume-template-box .name-title {
  font-size: 10px !important;
}
.scroll-resume-box .resume-zoom .styleTempDBSix .parent-right .name-title {
  font-size: 10px !important;
  margin-bottom: 0px;
  padding-top: 10px;
}
.scroll-resume-box div#socialInfo p {
  margin-bottom: 0px !important;
}
.scroll-resume-box .styleTempDBSix .border-bg {
  display: block;
  top: 86%;
  width: 100%;
  z-index: 2;
}
.scroll-resume-box .resume-zoom .top-heading {
  padding-bottom: 10px;
}
.scroll-resume-box .resume-heading {
  font-size: 6px !important;
  margin-bottom: 2px !important;
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 2px !important;
}
.scroll-resume-box .paragraph {
  font-size: 5px !important;
  line-height: inherit !important;
  margin-bottom: 0px !important;
}
.scroll-resume-box .resume-icon svg {
  width: 6px;
  height: 6px;
}
.scroll-resume-box div#socialInfo {
  margin-bottom: 0px !important;
}
.scroll-resume-box .resume-preview .resume-zoom .dummy-img {
  height: 40px !important;
  width: 40px !important;
}
.scroll-resume-box .resume-preview .resume-zoom .resume-template-box {
  height: 370px;
  overflow: hidden;
  background-color: #fff;
}
.scroll-resume-box .resume-preview {
  padding: 5px;
  border: 2px solid transparent;
}
.scroll-resume-box .resume-content-three .parent-col:nth-of-type(2) {
  padding-left: 10px;
  padding-right: 10px;
}
.final-resume-box .resume-template-box.temp-3 .resume-education.pl-3,
.scroll-resume-box
  .resume-template-box.temp-3
  .resume-education.pl-3
  .final-resume-box
  .resume-template-box.temp-3
  .skill-list.resume-skill.pl-3,
.scroll-resume-box .resume-template-box.temp-3 .skill-list.resume-skill.pl-3,
.final-resume-box .resume-content-one .resume-education.pl-3,
.final-resume-box .resume-content-one .skill-list.resume-skill.pl-3,
.scroll-resume-box .resume-template-box.temp-4 .resume-education.pl-3,
.scroll-resume-box .resume-template-box.temp-4 .resume-education.pl-3 {
  padding-left: 0px !important;
}
.scroll-resume-box .temp-4-pd .resume-content-box {
  margin: 14px;
}

.scroll-resume-box .styleTempOne .parent-col:nth-of-type(1) .profile-img-box {
  margin-right: 0px;
}
.scroll-resume-box .resume-zoom .temp-3 .top-fix-box {
  padding: 2% 7px !important;
  padding-left: 7px !important;
}
.scroll-resume-box .extra-icons-social {
  display: none;
}
.scroll-resume-box
  .resume-zoom
  .resume-template-box.temp-3
  .socail-info.resume-social-info
  p {
  font-size: 6px;
}
.scroll-resume-box .styleTempOne .resume-section .paragraph,
.scroll-resume-box .styleTempOne .resume-section .paragraph1,
.scroll-resume-box .styleTempThree .resume-section .paragraph,
.scroll-resume-box .styleTempThree .resume-section .paragraph1 {
  padding-left: 25px;
}
.scroll-resume-box .paragraph p {
  margin-bottom: 4px;
}
.scroll-resume-box .styleTempTwo span.resume-icon {
  display: none;
}
.scroll-resume-box .styleTempOne .styled-bg,
.scroll-resume-box .styleTempTwo .border-bg,
.scroll-resume-box .styleTempFour .border-bg {
  padding: 0.5px;
  margin-bottom: 0.8px !important;
}
.scroll-resume-box .styleTempFour .resume-heading,
.scroll-resume-box .styleTempTwo .resume-heading {
  background: #abadaf;
  padding: 4px;
  padding-top: 3px;
  padding-bottom: 1px !important;
  font-weight: 400 !important;
  font-size: 4px !important;
}
.scroll-resume-box .styleTempDBSix .resume-zoom .top-section {
  padding: 5px !important;
  padding-bottom: 0px !important;
}
.scroll-resume-box .styleTempDBSix .parent-col:nth-of-type(1) .parent-right {
  padding-left: 5px;
  padding-top: 10px;
}
.scroll-resume-box .styleTempDBEleven .temp-4-pd .parent-left {
  padding-right: 0px !important;
  padding: 10px !important;
}
.scroll-resume-box .resume-preview .resume-zoom.styleTempDBEleven .dummy-img {
  height: 55px !important;
  width: 100% !important;
}
.scroll-resume-box .resume-preview .resume-zoom.styleTempDBSeven .dummy-img {
  height: 55px !important;
  width: 55px !important;
}
.scroll-resume-box
  .styleTempDBSix
  .parent-col:nth-of-type(1)
  .parent-left
  .profile-pic {
  top: 15px;
  z-index: 3;
}
.scroll-resume-box
  .styleTempDBSix
  .resume-content-box
  .parent-col:nth-of-type(2) {
  padding-top: 30px !important;
}
.scroll-resume-box .styleTempDBSix .parent-col:nth-of-type(3) {
  padding-top: 15px !important;
}
.scroll-resume-box .parent-col:nth-of-type(2) {
  padding-left: 10px;
}
.scroll-resume-box .temp-4-pd .parent-col:nth-of-type(2) {
  padding-right: 10px;
}
.scroll-resume-box .resume-content-one .parent-col:nth-of-type(3),
.scroll-resume-box .temp-4-pd .parent-col:nth-of-type(3) {
  padding-left: 10px;
  padding-right: 10px;
}
.scroll-resume-box #workHistory ul {
  margin-bottom: 0px !important;
}
.scroll-resume-box .summay-box,
.socail-info,
.scroll-resume-box .work-history-box,
.language-box,
.scroll-resume-box .skill-list {
  margin-bottom: 5px;
}
.scroll-resume-box .percent-clr {
  height: 2px;
}
.scroll-resume-box .resume-content-three .parent-col:nth-of-type(2) {
  padding-top: 0px !important;
}
.scroll-resume-box .styleTempThree .parent-col:nth-of-type(1) .name-bg {
  margin-bottom: 0px !important;
  padding: 5px 15px !important;
}
.scroll-resume-box .styleTempThree .profile-img-box {
  margin-right: 5px;
}
.scroll-resume-box .styleTempThree .contact-info-box {
  padding-left: 3px;
}
.scroll-resume-box .styleTempDBTwelve .profile-pic .dummy-img-box {
  padding-left: 10px;
}
.scroll-resume-box .styleTempDBTwelve .profile-pic .addNew .dummy-img {
  width: 35px !important;
  height: 35px !important;
}
.scroll-resume-box .styleTempDBTwelve .top-section.parent-container {
  padding: 8px 0px !important;
  padding-bottom: 6px !important;
}
.scroll-resume-box
  .styleTempDBTen
  .temp-4-pd
  .parent-col:nth-of-type(1)
  .parent-right
  .opacit-1 {
  padding-left: 10px;
}
.scroll-resume-box .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 45px !important;
}
.scroll-resume-box .styleTempDBSeven .after-devider,
.scroll-resume-box .styleTempDBTen .after-devider,
.scroll-resume-box .styleTempDBEleven .after-devider {
  height: 1px;
}
.scroll-resume-box .styleTempDBTen .temp-4-pd .parent-left {
  padding: 10px !important;
}
.scroll-resume-box .resume-preview .resume-zoom.styleTempDBTen .dummy-img {
  height: 50px !important;
  width: 100% !important;
}
.scroll-resume-box .styleTempDBEleven .postition-bg-style.fade-bg {
  height: 150px;
  width: 170px;
}
.scroll-resume-box
  .styleTempDBEleven
  .temp-4-pd
  .parent-col:nth-of-type(1)
  .parent-right
  .opacit-1 {
  padding: 4px !important;
}
.scroll-resume-box .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 30px !important;
}
.scroll-resume-box .resume-preview .resume-zoom.styleTempDBNine .dummy-img {
  height: 50px !important;
  width: 70px !important;
}
.scroll-resume-box .styleTempDBNine .dummy-img-box {
  top: 0px;
}
.scroll-resume-box .styleTempDBSeven .parent-col:nth-of-type(2),
.scroll-resume-box .styleTempDBNine .parent-col:nth-of-type(2) {
  padding-top: 45px !important;
}
.scroll-resume-box .resume-preview .resume-zoom.styleTempDBEight .dummy-img {
  height: 65px !important;
  width: 100% !important;
}
.scroll-resume-box .resume-preview .styleTempDBEight .parent-col:nth-of-type(2),
.scroll-resume-box .styleTempDBEight .parent-col:nth-of-type(2) {
  padding-top: 45px !important;
}
.scroll-resume-box .styleTempThree .name-bg {
  margin-bottom: 3px !important;
  padding: 2px 10px !important;
}
/* template slider css end */
.popup-body {
  background: #ffa50000;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-body .grid.grid-cols-2 {
  background: #fff;
  padding: 15px 30px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.plan-box.right-section {
  background: #fff;
  display: inline-block;
  width: 45%;
  /* height: 425px; */
  overflow: hidden;
  padding: 20px;
}
.price-list {
  list-style: none;
  padding-left: 0px;
  border-bottom: 1px solid #c1c3c5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.price-list .list-icon {
  margin-right: 11px;
  display: inline-block;
  color: #666;
}
.bottom-img-pay img {
  margin: auto;
}
.pay-img {
  background: #fafdfe;
}
.card-img-logo img {
  height: 40px;
}

.title-text {
  width: 100%;
  max-width: 55%;
  margin: auto;
}

.text-details ul {
  line-height: 35px;
}
.image-content img {
  width: 100%;
}

#thankyoupage {
  /* height: calc(100vh - 67px); */
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 999; */
  /* position: relative; */
}
.pipe_line {
  background-color: #336565;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.8rem 0.8rem;
  width: -webkit-max-content;
  width: max-content;
  margin-bottom: 2.2rem;
}
.sup-title {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #fff;
  line-height: 123.5%;
}
.yellow_text {
  color: orange;
}
.site-title {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 2.25rem;
}

ul.list-icon li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 20px;
}
.btn_create {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff !important;
  padding: 0.5rem 1.25rem;
  background-color: orange;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid orange;
  margin-top: 0.5rem;
}
.bar_slidder {
  background: #cecccc;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 0.5rem;
  z-index: 9998;
}
.text-small {
  font-size: 13px;
}
.gap-3 {
  grid-gap: 5px;
  gap: 5px;
}
.desktop-hide {
    display: none;
}
@media only screen and (max-width: 992px) {
  #thankyoupage {
    display: block;
    height: 100%;
    padding: 50px 0px;
  }
  .text-details {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  ul.list-icon li {
    line-height: 25px;
  }
  .text-details ul {
    padding: 2px;
  }
  .pipe_line {
    width: 100%;
  }
  .site-btn {
    width: 100%;
    /* position: static; */
    /* position: fixed;
    bottom: 0; */
  }

  #thankyoupage {
    display: block;
    height: 100%;
    padding: 50px 0px;
  }

  #thankyoupage h2 {
    font-size: 20px;
  }

  #thankyoupage p {
    font-size: 14px;
    width: 100%;
    max-width: 100%;
  }
  .text-details {
    margin-top: 20px;
  }
}

img.resume-profile-img.dummy-box-img {
    object-fit: contain;
}
.temp-4-pd .parent-col:nth-child(3).fade-bg:after,
.temp-4-pd .parent-col:nth-child(1) .fade-bg:after {
  display: none;
}
.resume-table {
  position: relative;
  /* overflow: hidden; */
}
.final-resume-box .temp-4-pd #myeditablediv .signature-col {
    width: 64%;
    margin-left: 36%;
}
.styleTempOne .parent-col:nth-of-type(2) {
    padding-top: 25px !important;
}
.final-resume-box .resume-template-box.temp-3 .parent-col:nth-of-type(2) {
  padding-bottom: 100px;
}
.styleTempDBSix h1.name-title,
.styleTempOne h1.name-title,
.styleTempThree h1.name-title,
.styleTempFive h1.name-title {
  color: inherit !important;
}
.temp-4-pd .parent-left {
  padding-right: 0px !important;
}
.template-section .resume-zoom .dummy-img,
.resume-preview .resume-zoom .dummy-img {
  height: 80px !important;
  width: 80px !important;
}
.temp-4-pd .parent-col.pt-4.fade-bg:nth-child(3) {
  background: #fff !important;
  color: #000 !important;
}
.parent-right .name-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500 !important;
}
.temp-4 .profile-pic .mt-2 {
  display: none;
}
.temp-4-pd .parent-col:nth-of-type(2),
.temp-4-pd .parent-col:nth-of-type(3) {
  min-height: 100%;
}
.temp-4-pd .parent-col:nth-of-type(2).fade-bg *,
.temp-4-pd .parent-col:nth-of-type(3).fade-bg * {
  z-index: 2 !important;
  position: relative;
}
.resume-template-box,
.resume-content-box {
  padding: 0px !important;
  margin: 0px !important;
}
.resume-preview .resume-zoom .preview-inner {
  height: 100% !important;
}
.resume-table.pb-5 {
  height: 100%;
  padding-bottom: 0px !important;
}
.percent-box,
.percent {
  width: 80%;
}
.styleTempOne .percent-box,
.styleTempOne .percent,
.styleTempTwo .percent-box,
.styleTempTwo .percent,
.styleTempThree .percent-box,
.styleTempThree .percent,
.styleTempFour .percent-box,
.styleTempFour .percent,
.styleTempFive .percent-box,
.styleTempFive .percent {
  width: 50%;
}
.template-grid .styleTempThree .mb-3.name-bg {
  margin-bottom: 8px !important;
}
.percent-clr {
  width: 17% !important;
}
.template-grid .percent-clr {
  height: 3px;
}
.template-grid .resume-heading {
  font-size: 8px;
}
.template-grid .profile-img-box {
  margin-right: 0px;
}
.template-grid h1.name-title {
  font-size: 16px !important;
}
.template-grid .resume-preview-box .resume-temp {
  padding: 0px;
}
.template-grid .styleTempFour .resume-heading,
.template-grid .styleTempTwo .resume-heading {
  padding: 3px;
  font-size: 8px;
}
.styleTempOne .resume-heading,
.styleTempFive .resume-heading,
.styleTempThree .resume-heading,
.styleTempDBTwelve .resume-heading {
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.template-grid .styleTempOne .resume-heading,
.template-grid .styleTempFive .resume-heading,
.template-grid .styleTempThree .resume-heading,
.template-grid .styleTempDBTwelve .resume-heading {
  padding-bottom: 3px;
  border-bottom: 1px solid #ccc;
}
.styleTempOne .resume-section .paragraph,
.styleTempOne .resume-section .paragraph1,
.styleTempThree .resume-section .paragraph,
.styleTempThree .resume-section .paragraph1 {
  padding-left: 70px;
}
.styleTempFour .resume-section .paragraph,
.styleTempFour .resume-section .paragraph1,
.styleTempFive .resume-section .paragraph,
.styleTempFive .resume-section .paragraph1 {
  padding-left: 50px;
}
.template-grid .styleTempOne .opacit-1,
.template-grid .styleTempThree .opacit-1 {
  grid-gap: 10px;
  gap: 10px;
}
.template-grid .styleTempTwo div#socialInfo {
  margin-bottom: 3px;
  padding-bottom: 0px;
}
.template-grid .styleTempFour .profile-content {
  padding-top: 5px;
  padding-bottom: 5px;
}
.styleTempFour .profile-content {
  padding-top: 10px;
  padding-bottom: 10px;
}
.template-grid .profile-img-box .mt-2 {
  display: none;
}
/* template grid css end */
.resume-template-box .upload-photo-btn {
  display: none !important;
}
.resume-preview .percent-clr {
  width: 17% !important;
}
.parent-col:last-child {
  padding-bottom: 100px;
}
.choose-template-section.template-section .col-lg-4.mb-3 {
  padding: 3px 11px;
}
.template-section .resume-preview-box .resume-temp .resume-template-box {
  height: 520px;
  overflow: hidden;
  margin-bottom: 10px;
}
.mb-3.styled-bg {
  margin-bottom: 0px !important;
}
.choose-template-section .styleTempOne .top-fix-box,
.choose-template-section .styleTempTwo .top-fix-box,
.choose-template-section .styleTempThree .top-fix-box,
.choose-template-section .styleTempFour .top-fix-box {
  background: transparent !important;
  color: initial !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 15px !important;
}
.template-section .styleTempThree .top-fix-box,
.styleTempThree .slide-bg-clr.top-fix-box {
  padding: 0px !important;
}
.template-grid .styleTempThree .contact-info-box {
  padding-left: 15px !important;
}
.styleTempThree .profile-img-box {
  margin-right: 15px;
  margin-left: 15px;
}
.styleTempThree .profile-img-box .mt-2 {
  display: none;
}
.styleTempThree h1.name-title {
  margin: 5px 0px;
}
.choose-template-section .styleTempTwo .resume-social-info .resume-icon {
  display: none;
}
.template-section .extra-icons-social {
  display: none;
}
.styled-bg {
  display: none;
}
.template-section .profile-img-box .upload-photo-btn {
  display: none !important;
}
.border-bg {
  display: none;
  padding: 1.5px;
}
.styleTempOne,
.styleTempTwo,
.styleTempThree,
.styleTempFour {
  background: #fff;
}

.styleTempOne .language-box,
.styleTempTwo .language-box,
.styleTempFour .language-box,
.styleTempThree .language-box,
.styleTempFive .language-box {
  width: 100%;
}

.styleTempOne .styled-bg,
.styleTempTwo .border-bg,
.styleTempFour .border-bg {
  display: block;
  padding: 1px;
  margin-bottom: 1px !important;
}
.template-grid .styleTempOne .styled-bg,
.template-grid .styleTempTwo .border-bg,
.template-grid .styleTempFour .border-bg {
  padding: 0.7px;
}
.styleTempOne .top-fix-box,
.styleTempTwo .top-fix-box,
.styleTempThree .top-fix-box,
.styleTempFour .top-fix-box {
  background: transparent !important;
  color: initial !important;
  padding: 0px 0px;
}
.styleTempOne .resume-heading,
.styleTempTwo .resume-heading {
  color: initial !important;
}
.styleTempOne .top-fix-box .resume-heading,
.styleTempTwo .top-fix-box .resume-heading {
  display: none;
}
.styleTempOne .opacit-1,
.styleTempThree .opacit-1 {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}
.styleTempThree .opacit-1 {
  flex-direction: row-reverse;
  justify-content: space-between;
}
.styleTempThree .contact-info-box {
  padding-left: 20px;
}
.final-resume-box .styleTempThree .contact-info-box {
  padding-left: 25px;
}
.styleTempThree .profile-img-box {
  margin-right: 20px;
}
/* .styleTempTwo .skill-list.resume-skill,
.styleTempFour .skill-list.resume-skill {
  padding-top: 15px !important;
} */
.final-resume-box .styleTempTwo .parent-col:nth-of-type(2),
.final-resume-box .styleTempFour .parent-col:nth-of-type(2) {
  padding-top: 20px !important;
}
/* style temp one */
.final-resume-box .styleTempOne .styled-bg {
  padding: 20px !important;
}
.styleTempOne .profile-img-box {
  display: block !important;
  margin-right: 15px;
}
.styleTempOne .top-fix-box {
  padding: 0px 15px;
}
.styleTempOne .name-title {
  font-size: 2rem;
  font-weight: 600 !important;
}

.styleTempOne .profile-img-box span.btn.site-btn {
  display: none !important;
}
/* style temp two */
.styleTempTwo div#socialInfo {
  text-align: right;
  padding-right: 20px;
}
.styleTempTwo .resume-icon {
  display: none;
}
.styleTempTwo div#socialInfo {
  text-align: right;
  padding-right: 0px;
  line-height: 1.1;
  padding-bottom: 0px;
}
.styleTempTwo .resume-section ul {
  padding-left: 30px;
}
.final-resume-box .styleTempTwo .parent-col:nth-of-type(1) {
  padding: 10px !important;
}
.final-resume-box .styleTempFour .parent-col:nth-of-type(1) {
  padding: 7px !important;
}
.final-resume-box .styleTempTwo .border-bg,
.final-resume-box .styleTempFour .border-bg {
  padding: 1.5px;
  margin-bottom: 3px !important;
}
/* style temp three */
.styleTempThree .profile-img-box {
  display: block !important;
}
.styleTempThree .name-bg {
  display: block !important;
  text-align: right;
}
.styleTempThree .top-fix-box h1.name-title,
.styleTempThree .top-fix-box h1.resume-heading {
  display: none;
}
.styleTempFour .border-bg {
  display: block !important;
}
/* style temp Four */
.styleTempFour .name-title {
  text-align: center;
}
.styleTempFour .top-fix-box .resume-heading {
  display: none;
}
.profile-content .profile-img-box {
  display: none !important;
}
.template-grid .styleTempFour .profile-content .profile-img-box {
  margin-right: 10px;
}
.styleTempFour .profile-content .profile-img-box {
  display: block !important;
  margin-right: 15px;
}
.styleTempFour .profile-content {
  display: flex;
  padding-bottom: 10px;
}
.styleTempFour .profile-content .resume-social-info {
  margin-top: 0px;
}
.styleTempFour .resume-heading,
.styleTempTwo .resume-heading {
  background: #abadaf;
  padding: 5px;
  color: #fff !important;
  font-weight: 400 !important;
}
/* style temp five */
.final-resume-box .styleTempFive .parent-col:nth-of-type(2) {
  padding-top: 20px !important;
}
.styleTempFive .slide-bg-clr.top-fix-box {
  padding: 25px;
}
.styleTempFive .profile-img-box {
  margin-right: 5px !important;
}
.final-resume-box .styleTempFive .profile-img-box {
  margin-right: 0px !important;
}
.styleTempFive div#socialInfo span.resume-icon {
  display: none;
}
.styleTempFive div#socialInfo p {
  padding-left: 0px;
}
.styleTempFive .profile-content .profile-img-box {
  display: block !important;
}
.styleTempFive .profile-content {
  display: flex;
}
.styleTempFive .profile-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.template-grid .styleTempFive .profile-content {
  grid-gap: 10px;
  gap: 10px;
}
.styleTempFive .profile-content {
  grid-gap: 15px;
  gap: 15px;
}
.styleTempFive .profile-content .resume-social-info .resume-heading {
  display: none;
}
.styleTempFive h1.name-title {
  display: none;
}
.styleTempFive .profile-content .info-name,
.styleTempFive .profile-content .name-title {
  display: block !important;
}
.template-grid .styleTempFive .profile-img-box {
  margin-right: 3px;
}
.styleTempFive .top-fix-box {
  margin-bottom: 10px;
}
/* style temp Six */
.styleTempDBSix .temp-4-pd .parent-col:nth-child(1).fade-bg:after {
  display: none;
}
.final-resume-box .styleTempDBSix .parent-right .name-title {
  position: relative;
  top: 20px;
  font-size: calc(28px + 1.5vw) !important;
}
.final-resume-box .styleTempDBSix .parent-right {
  padding-top: 15px;
  /* padding-left: 0px !important; */
}
.styleTempDBSix .border-bg {
  display: block;
}
.styleTempDBSix .top-section {
  padding: 0px !important;
}
.styleTempDBSix .parent-col:nth-of-type(2) {
  width: 34% !important;
}
.styleTempDBSix .parent-col:nth-of-type(3) {
  width: 66%;
  padding-top: 20px !important;
}
.styleTempDBSix .profile-pic .mt-2 {
  display: none;
}
.styleTempDBSix .resume-content-box .parent-col:nth-of-type(2),
.styleTempDBSix .resume-content-box .parent-col:nth-of-type(3) {
  margin-top: 0px;
  min-height: 100%;
}
.final-resume-box
  .styleTempDBSix
  .resume-content-box
  .parent-col:nth-of-type(2),
.final-resume-box
  .styleTempDBSix
  .resume-content-box
  .parent-col:nth-of-type(3) {
  min-height: 800px;
  height: 100%;
}

.styleTempDBSix .resume-content-box .parent-col:nth-of-type(2) {
  background-color: #f3f3f3;
  padding-top: 50px !important;
}
.final-resume-box .styleTempDBSix .parent-col:nth-of-type(1) {
  margin-bottom: 16px;
}
.styleTempDBSix .parent-col:nth-of-type(1) {
  margin-bottom: -4px;
}
.styleTempDBSix .parent-col:nth-of-type(1) .parent-left .profile-pic {
  display: flex;
  justify-content: center;
  position: relative;
  top: 40px;
}
.final-resume-box
  .styleTempDBSix
  .parent-col:nth-of-type(1)
  .parent-left
  .profile-pic {
  top: 80px;
}
.final-resume-box .styleTempDBSix .parent-col:nth-of-type(3) {
  padding-top: 35px !important;
}
.styleTempDBSix .parent-col:nth-of-type(1) .parent-right {
  padding-left: 15px;
}
.template-grid
  .styleTempDBSix
  .parent-col:nth-of-type(1)
  .parent-right
  .name-title,
.resume-zoom .styleTempDBSix .parent-right .name-title {
  padding-top: 15px;
  margin-bottom: 15px;
  font-size: 30px !important;
  font-weight: 500 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.final-resume-box .styleTempDBSix .parent-col:nth-of-type(1) .parent-right {
  padding-top: 20px;
}
.final-resume-box
  .styleTempDBSix
  .resume-content-box
  .parent-col:nth-of-type(2) {
  padding-top: 80px !important;
}
.styleTempDBSix .parent-col:nth-of-type(1) .parent-right .after-devider,
.styleTempDBTwelve .parent-col:nth-of-type(1) .parent-right .after-devider {
  display: none;
}
.styleTempDBSix .resume-table.pb-5 {
  height: 100%;
  padding-bottom: 0px !important;
}
.final-resume-box .styleTempDBSix .parent-col:nth-of-type(1) .border-bg {
  position: relative;
  top: 20px;
  padding: 3px;
}
.styleTempDBSix .top-section.parent-container .parent-left {
  width: 34%;
}
/* style Temp Seven */
.styleTempDBSeven .top-section,
.styleTempDBEight .top-section,
.styleTempDBNine .top-section,
.styleTempDBTen .top-section,
.styleTempDBEleven .top-section {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.styleTempDBSix .parent-col:nth-child(2).fade-bg *,
.styleTempDBSix .parent-col:nth-child(3).fade-bg *,
.styleTempDBEight .parent-col:nth-child(2).fade-bg *,
.styleTempDBEight .parent-col:nth-child(3).fade-bg *,
.styleTempDBTen .parent-col:nth-child(3).fade-bg *,
.styleTempDBTen .parent-col:nth-child(2).fade-bg *,
.styleTempDBEleven .parent-col:nth-child(2).fade-bg *,
.styleTempDBEleven .parent-col:nth-child(3).fade-bg *,
.styleTempDBTwelve .parent-col:nth-child(2).fade-bg *,
.styleTempDBTwelve .parent-col:nth-child(3).fade-bg * {
  color: #000;
}
.styleTempDBSeven .parent-col.fade-bg:after,
.styleTempDBNine .parent-col.fade-bg:after {
  display: none;
}
.styleTempDBSeven .parent-col.fade-bg h1.resume-heading,
.styleTempDBNine .parent-col.fade-bg h1.resume-heading {
  color: #fff !important;
}
.template-grid .styleTempDBSeven .dummy-img,
.resume-preview .styleTempDBSeven .dummy-img,
.resume-preview .styleTempDBNine .dummy-img {
  height: 90px !important;
  width: 90px !important;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.resume-preview .styleTempDBNine .dummy-img {
  border-radius: 0%;
}
.styleTempDBSeven .dummy-img {
  height: 180px !important;
  width: 180px !important;
  border-radius: 50%;
}
.styleTempDBSeven .dummy-img .resume-profile-img {
  border-radius: 50%;
}
.styleTempDBSeven .dummy-img-box {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10px);
  top: 5px;
}
.styleTempDBSeven .resume-profile-img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}
.styleTempDBSeven .parent-col:nth-of-type(2),
.styleTempDBNine .parent-col:nth-of-type(2) {
  padding-right: 15px;
  border-left: none;
  padding-top: 80px !important;
}
.styleTempDBSeven .top-section .parent-right,
.styleTempDBEight .top-section .parent-right,
.styleTempDBTen .top-section .parent-right,
.styleTempDBEleven .top-section .parent-right {
  background: #fff;
  color: #000;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 0px;
  width: 66%;
}
.styleTempDBSeven .after-devider,
.styleTempDBTen .after-devider,
.styleTempDBEleven .after-devider {
  height: 2px;
  width: 100%;
}
.template-grid .styleTempDBSeven .after-devider,
.template-grid .styleTempDBTen .after-devider,
.template-grid .styleTempDBEleven .after-devider {
  height: 1px;
}
.template-section .styleTempDBSeven .parent-container,
.styleTempDBSeven .resume-preview .resume-zoom .parent-container {
  padding-left: 0px;
  padding-right: 0;
}
.styleTempDBSeven .top-bg-clr,
.styleTempDBEight .top-bg-clr,
.styleTempDBNine .top-bg-clr,
.styleTempDBTen .top-bg-clr,
.styleTempDBEleven .top-bg-clr,
.final-resume-box .styleTempDBNine .parent-col:nth-child(1) {
  background: #fff !important;
}
.final-resume-box .styleTempDBSeven .parent-col:nth-of-type(2) {
  padding-top: 150px !important;
}
/* temp style eight */
.final-resume-box .styleTempDBEight .dummy-img {
  height: 230px !important;
}
.styleTempDBEight .parent-left.parent-left-bg {
  background: transparent !important;
}
.styleTempDBEight .dummy-img-box {
  position: absolute;
  width: 100%;
}
/* .styleTempDBEight .dummy-img-box .addNew {
  width: 100%;
  height: 105px;
} */
.styleTempDBEight .dummy-img-box .addNew {
  width: 100% !important;
}
.final-resume-box .styleTempDBEight .resume-profile-img {
  height: 100% !important;
  width: 100% !important;
}
.resume-preview .resume-zoom .styleTempDBEight .dummy-img,
.template-section .styleTempDBEight .dummy-img {
  height: 130px !important;
}
.template-section .styleTempDBEight .dummy-img,
.resume-preview .styleTempDBEight .dummy-img,
.final-resume-box .styleTempDBEight .dummy-img,
.final-resume-box .styleTempDBEight .resume-profile-img {
  width: 100% !important;
}
.resume-preview .styleTempDBEight .parent-col:nth-of-type(2),
.styleTempDBEight .parent-col:nth-of-type(2) {
  padding-top: 100px !important;
}
.final-resume-box .styleTempDBEight .parent-col:nth-of-type(2) {
  padding-top: 160px !important;
}
.styleTempDBEight .parent-left {
  padding-right: 0px;
}
.template-grid .styleTempDBEight h1.name-title,
.template-grid .styleTempDBTen h1.name-title,
.template-grid .styleTempDBEleven h1.name-title {
  font-size: 20px !important;
}

/* temp style Nine */
.styleTempDBNine .dummy-img-box {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 10px);
  top: 5px;
}
.final-resume-box .styleTempDBNine .dummy-img-box {
  left: 55%;
}
.template-section .styleTempDBNine .dummy-img {
  height: 90px !important;
  width: 103px !important;
}
.resume-preview .styleTempDBNine .dummy-img {
  height: 90px !important;
  width: 110px !important;
}
.final-resume-box .styleTempDBNine .dummy-img {
  width: 100% !important;
}
.styleTempDBNine .parent-col:nth-of-type(1) .parent-right .name-title {
  font-weight: 500 !important;
  color: #000 !important;
  padding-top: 15px;
}
.template-grid
  .styleTempDBNine
  .parent-col:nth-of-type(1)
  .parent-right
  .name-title,
.resume-zoom
  .styleTempDBNine
  .parent-col:nth-of-type(1)
  .parent-right
  .name-title {
  font-size: 30px !important;
}

.styleTempDBNine .after-devider,
.styleTempDBEight .after-devider {
  display: none;
}
.final-resume-box .styleTempDBNine .dummy-img-box,
.final-resume-box .styleTempDBNine .dummy-img {
  border-radius: 0px !important;
  width: 90%;
}
.styleTempDBNine .temp-4-pd .parent-col:nth-of-type(3) {
  padding-top: 10px !important;
}
.final-resume-box .styleTempDBNine .parent-col:nth-of-type(2) {
  padding-top: 150px !important;
}
.temp-4-pd .parent-col.pt-4.fade-bg:nth-child(3) h1.resume-heading {
  color: #000 !important;
}
.styleTempDBNine .top-section .parent-right {
  width: 66%;
}
.final-resume-box .temp-4-pd .parent-col:nth-of-type(3),
.final-resume-box .temp-4-pd .parent-col:nth-of-type(2) {
  padding-left: 25px;
  padding-right: 25px;
}
.final-resume-box .parent-col:nth-of-type(1) .parent-right {
  /* font-size: 40px !important; */
  padding: 25px;
  padding-bottom: 15px;
  width: 66%;
}
.styleTempDBNine .dummy-img {
  height: 180px !important;
  width: 180px !important;
}
.styleTempDBNine .resume-profile-img {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
}
.final-resume-box .styleTempDBNine .addNew {
  width: 90%;
}
.final-resume-box .styleTempDBNine .temp-4-pd .parent-left {
  padding-right: 0px !important;
  padding-top: 15px;
}
/* style temp ten */
.styleTempDBEight .dummy-img-box {
  position: absolute;
  width: 100%;
}
/* .styleTempDBEight .dummy-img-box .addNew {
  width: 100%;
  height: 105px;
} */
.styleTempDBTen .dummy-img,
.styleTempDBEleven .dummy-img,
.styleTempDBTen .dummy-img-box .addNew,
.styleTempDBEleven .dummy-img-box .addNew,
.template-section .styleTempDBTen .dummy-img,
.template-section .styleTempDBEleven .dummy-img,
.resume-preview .styleTempDBTen .dummy-img,
.resume-preview .styleTempDBEleven .dummy-img {
  width: 100% !important;
}
/* .styleTempDBTen .resume-profile-img,
.template-section .styleTempDBTen .dummy-img {
  height: 90px !important;
} */
.resume-preview .styleTempDBTen .dummy-img,
.resume-preview .styleTempDBEleven .dummy-img {
  height: 100px !important;
}
.final-resume-box .styleTempDBTen .resume-profile-img,
.final-resume-box .styleTempDBEleven .resume-profile-img {
  height: 100% !important;
  width: 100%;
}
.styleTempDBTen .temp-4-pd .parent-left {
  padding-right: 0px !important;
  padding: 14px !important;
}
.final-resume-box .styleTempDBTen .temp-4-pd .parent-left,
.final-resume-box .styleTempDBEleven .temp-4-pd .parent-left {
  padding: 30px !important;
}
.styleTempDBTen .parent-left-bg,
.styleTempDBEleven .parent-left-bg {
  position: absolute;
  z-index: 2;
}
.styleTempDBTen .temp-4-pd .parent-col:nth-of-type(1) .parent-right,
.styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(1) .parent-right {
  width: 100%;
  padding-left: 34%;
}
.styleTempDBTen .temp-4-pd .parent-col:nth-of-type(1) .parent-right .opacit-1 {
  padding-left: 15px;
}
.final-resume-box
  .styleTempDBTen
  .temp-4-pd
  .parent-col:nth-of-type(1)
  .parent-right
  .opacit-1 {
  padding-left: 25px;
}
.styleTempDBTen h1.name-title {
  color: #000 !important;
}
.styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 90px !important;
}
.final-resume-box .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 170px !important;
}
/* style temp eleven */
.final-resume-box .styleTempDBEleven .postition-bg-style.fade-bg:after {
  z-index: 0 !important;
}
.final-resume-box .styleTempDBEleven .resume-table.pb-5 {
  overflow: hidden;
}
.styleTempDBEleven .parent-left-bg {
  background: transparent !important;
}
.styleTempDBEleven .parent-col:nth-of-type(2),
.styleTempDBTwelve .parent-col:nth-of-type(2) {
  background: transparent !important;
}
.styleTempDBEleven .parent-col:nth-of-type(2):after,
.styleTempDBTwelve .parent-col:nth-of-type(2):after {
  display: none;
}
.styleTempDBEleven .postition-bg-style.fade-bg {
  display: block !important;
  padding: inherit;
  position: absolute;
  z-index: 1;
  height: 200px;
  width: 230px;
  border-radius: 50%;
  top: -50px;
  left: -50px;
}
.styleTempDBEleven .temp-4-pd .parent-left {
  padding-right: 0px !important;
  padding: 25px !important;
}
.styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 85px !important;
}
.template-grid .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 65px !important;
}
.styleTempDBEleven .after-devider {
  display: none;
}
.styleTempDBEleven .resume-heading {
  border-top: 1px solid #ccc;
  padding-top: 6px !important;
  margin-top: 5px;
}
.template-grid .styleTempDBEleven .resume-heading {
  padding-top: 3px;
}
.styleTempDBEleven .temp-4-pd .parent-col.pt-4.fade-bg:nth-child(3) {
  background: transparent !important;
}
.final-resume-box .styleTempDBEleven .temp-4-pd .parent-col:nth-child(3) {
  z-index: 3;
}
.styleTempDBEleven
  .temp-4-pd
  .parent-col:nth-of-type(1)
  .parent-right
  .opacit-1 {
  padding-left: 15px !important;
}
.final-resume-box .styleTempDBEleven .postition-bg-style.fade-bg {
  height: 380px;
  width: 50%;
  top: -60px;
  left: -60px;
  z-index: 2;
}
.postition-bg-style.fade-bg:after {
  border-radius: 47%;
}
/* .final-resume-box .styleTempDBEleven .dummy-img-box {
  width: 80% !important;
  padding-left: 20px;
} */
.styleTempDBEleven .parent-col:nth-child(3).fade-bg:after {
  display: none;
}
.styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(3),
.styleTempDBEleven
  .temp-4-pd
  .parent-col:nth-of-type(1)
  .parent-right
  .opacit-1 {
  padding-left: 5px;
}
.final-resume-box .styleTempDBEleven .temp-4-pd .parent-col:nth-child(3) {
  padding-left: 15px;
}

.final-resume-box .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
  padding-top: 130px !important;
}
.final-resume-box .styleTempDBEleven .resume-heading {
  border-top: 2px solid #ccc;
  padding-top: 15px !important;
}
.styleTempDBEleven .parent-col:nth-child(3) {
  position: relative;
  z-index: 1;
  padding-top: 0px !important;
}
/* style temp twelve */
.styleTempDBTwelve .temp-4-pd .parent-col:nth-child(1).fade-bg:after,
.styleTempDBTwelve .temp-4-pd .parent-col:nth-child(1) .fade-bg:after {
  display: block;
}
.styleTempDBTwelve .profile-pic .addNew .dummy-img {
  width: 60px !important;
  overflow: hidden;
  height: 60px !important;
}
.styleTempDBTwelve .profile-pic .dummy-img-box {
  padding-left: 25px;
}
.template-grid
  .styleTempDBTwelve
  .parent-col:nth-of-type(1)
  .parent-right
  .name-title {
  font-size: 30px !important;
  font-weight: 500 !important;
}
.styleTempDBTwelve .top-section.parent-container {
  padding: 12px 0px !important;
  padding-bottom: 10px !important;
}
.styleTempDBTwelve .temp-4-pd .parent-col:nth-of-type(2),
.styleTempDBTwelve .temp-4-pd .parent-col:nth-of-type(3) {
  padding-top: 25px !important;
}
.styleTempDBTwelve .parent-left.parent-left-bg {
  background: transparent !important;
}
.final-resume-box .styleTempDBTwelve .profile-pic .addNew .dummy-img {
  width: 135px !important;
  height: 135px !important;
}
.styleTempDBTwelve .addNew {
  overflow: hidden;
}
.final-resume-box .styleTempDBTwelve .parent-col:nth-of-type(1) .parent-right {
  padding-top: 5px;
  padding-bottom: 0px;
}
.final-resume-box .styleTempDBTwelve .top-section.parent-container {
  padding: 22px 0px !important;
  padding-bottom: 20px !important;
}
@media print {
  .bg-light,
  body {
    background-color: white !important;
  }
  * {
    page-break-before: avoid; /* Prevent page break before the element */
    page-break-after: avoid; /* Prevent page break after the element */
    page-break-inside: avoid; /* Prevent page break within the element */
  }
  body {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
    margin: 0;
  }
  .temp-4-pd .resume-content-box .parent-col:nth-of-type(2),
  .temp-4-pd .resume-content-box .parent-col:nth-of-type(3),
  .temp-4-pd .resume-content-box {
    margin-top: 0px;
    height: 100%;
    min-height: 95vh;
    align-items: stretch;
  }
  .styleTempOne .slide-bg-clr.top-fix-box {
    margin-top: 10px;
  }
  .styleTempDBSix h1.name-title {
    margin-top: 30px;
  }
  body .styleTempDBSix .dummy-img {
    height: 500px;
    width: 500px;
  }
  .dummy-img img {
    height: 100% !important;
    width: 100% !important;
  }
  body .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 200px !important;
  }
  body .parent-col:nth-of-type(2),
  body .parent-col:nth-of-type(3),
  .parent-col:nth-of-type(1) .parent-right .opacit-1 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  body img.resume-profile-img {
    object-fit: cover !important;
    object-position: top center !important;
  }
  body .styleTempDBEleven .postition-bg-style.fade-bg {
    height: 400px !important;
    width: 600px !important;
    top: -100px;
    left: -100px;
    z-index: 2;
  }
  body .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(3) {
    z-index: 3;
  }
  body .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 150px !important;
  }
  body .styleTempDBSeven .dummy-img {
    height: 230px !important;
    width: 230px !important;
  }
  body .styleTempDBSeven .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 200px !important;
  }
  body .styleTempDBSeven .temp-4-pd .parent-col:nth-of-type(3) {
    padding-top: 30px !important;
  }
  body .styleTempDBSeven .parent-col:nth-of-type(1) .parent-right {
    padding-left: 0px !important;
  }
  body .styleTempDBTen .temp-4-pd .parent-left,
  body .styleTempDBEleven .temp-4-pd .parent-left {
    padding: 40px !important;
  }
  body .styleTempDBEleven .dummy-img .parent-left {
    padding-left: 0px;
  }
  body .styleTempDBTwelve .profile-pic .addNew .dummy-img {
    width: 150px !important;
    height: 150px !important;
  }
  body .styleTempDBTwelve .top-section.parent-container {
    padding: 32px 0px !important;
    padding-bottom: 30px !important;
    padding-left: 10px !important;
  }
  body .styleTempDBSix .parent-right .name-title {
    top: 15px;
    font-size: calc(30px + 1.5vw) !important;
  }
  .styleTempThree .contact-info-box {
    padding-left: 35px;
  }
  body .styleTempDBNine .parent-col:nth-child(1) {
    background: #fff !important;
  }
  body .styleTempDBNine .dummy-img {
    height: 250px !important;
    width: 300px !important;
  }
  .styleTempDBNine .temp-4-pd .parent-left {
    padding-top: 20px;
  }
  .styleTempDBNine .parent-col:nth-of-type(2) {
    padding-top: 270px !important;
  }
  .resume-template-box.temp-3 .parent-col:nth-of-type(2) {
    padding-bottom: 200px;
  }
  .styleTempOne .styled-bg {
    padding: 20px !important;
  }
  body .styleTempOne .top-fix-box {
    padding-top: 15px !important;
  }
  .styleTempTwo .parent-col:nth-of-type(1),
  .styleTempFour .parent-col:nth-of-type(1) {
    padding: 30px !important;
  }
  body .styleTempTwo .border-bg,
  body .styleTempFour .border-bg {
    padding: 1.5px;
    margin-bottom: 4px !important;
  }

  .styleTempTwo .parent-col:nth-of-type(2),
  .styleTempFour .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
  body .styleTempFour .language-box {
    margin-bottom: 40px !important;
  }
  .styleTempFive .slide-bg-clr.top-fix-box {
    padding: 35px;
  }
  .styleTempFive .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
 .styleTempDBEight .dummy-img {
     height: 250px !important;
    width: 352px !important;
}
.styleTempDBEight .parent-col:nth-of-type(2) {
    padding-top: 220px !important;
}
body .temp-4-pd #myeditablediv .signature-col {
    width: 64% !important;
    margin-left: 36% !important;
}
}

:root {
--yellow:#FFA500 !important;
--green:#27616E !important;
--blue: #27616E !important;
--dark-green:#1a584b;
--light-blue:#e9eefd;
--navy-blue:#2027aa;
--dark-blue: #3364FF;
--primary-blue:#3364FF;
--text-color:#222;
--light-dark:#919191;
--white:#fff;
--light-grey:#ddd;
--light-bg: #f6f8fa;
--dark: #111 !important;
--resume-text:#777;
--resume-heading:#222;
--heading-resume:#222;
--resume-bg: #f5f5f5;
/* resume color */
--success-steel:#0e1012;
--essential-ecru:#db7c11;
--clever-blue:#0f355d;
--quality-azure:#0187de;
--delight-mint:#64a381;
--standout-ruby:#690107;
--savvy-salmon:#fe7a66;
--optimistic-amber:#10657e !important;
--root-color:#808d97;
--light-grey:#d4d6d7;
--default-template:#404040;
}
@media (min-width: 1200px) {
  /* .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1000px !important;
  } */
  /* .final-resume-container .container,
  .resume-slide-box .container{
    max-width: 1060px !important;
  } */
.accessbility-page .container{
    max-width: 1170px !important;
  }
  .template-section .container {
    max-width: 1250px !important;
  }
  .customer-sec-body .container {
    max-width: 1180px !important;
  }
}
.underline {
  text-decoration: underline !important;
}
a:hover {
  text-decoration: none !important;
}
.text-decoration:hover {
  text-decoration: underline !important;
}
a {
  color: var(--blue) !important;
}
.btn,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
}
.exp-screen-section,
.choose-template-section {
  min-height: calc(100vh - 55px) !important;
}
.choose-template-section {
  padding-bottom: 90px;
}
.pb-50{
padding-bottom: 2.5rem;
}
.pt-50{
padding-top: 2.5rem;
}
html{
  scroll-behavior: smooth;
}

/* .exp-screen-section,
.step-box,
.choose-template-section {
  padding-bottom: 140px;
} */
.cursor-pointer {
  cursor: pointer;
}
.f-14 {
  font-size: 14px;
}
.custm-btn,
.custm-btn:hover {
  max-width: 330px;
  font-size: 16px;
  padding: 10px 40px !important;
  background: #ff6d05!important;
  text-align: center;
  color: var(--white);
  font-weight: 600;
  border-radius: 0px;
  text-decoration: none !important;
  display: inline-block;
  border: none;
  box-shadow: 0 0 5px rgb(0 0 0 / 19%);
  text-transform: uppercase;
  width: 100%;
}
.chosse-page-title {
  font-size: 30px;
  margin-bottom: 11px;
  margin-top: 6px;
}
.semi-bold {
  font-weight: 600 !important;
}
.bold {
  font-weight: 700 !important;
}
.uppercase {
  text-transform: uppercase;
}
.inline-block {
  display: inline-block;
}
.footer-choose-btn {
  position: fixed;
  bottom: 0;
  z-index: 9;
  width: 100%;
  box-shadow: 2px -2px 2px rgb(0 0 0 / 16%);
  background-color: #fbfbfb;
  padding: 15px 0;
  display:none;
}
.site-btn {
  font-size: 14px !important;
  color: #333 !important;
  border-color: #919191 !important;
  /* background-color: transparent !important; */
  font-weight: 700 !important;
  border: 1px solid transparent !important;
  padding: 12px 20px !important;
  text-transform: uppercase;
  box-shadow: none !important;
  /* box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%) !important; */
}
.btn.bg-blue {
    background-color: #ff6d05 !important;
}
.site-btn:hover {
  opacity: 0.9;
  background-color: #e8ecf0;
  box-shadow: 0 0 6px rgb(88 88 95 / 11%);
}
.border-btn {
  border: 1px solid var(--light-dark) !important;
}
.bg-blue {
  background-color: var(--yellow) !important;
}
.text-blue {
  color: var(--green) !important;
}
.text-white {
  color: var(--white) !important;
}
.btn-primary {
  background-color: var(--primary-blue) !important;
}
.prev-btn {
  color: var(--blue);
  background-color: #f6f8fa;
  font-weight: 600 !important;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  border: 1px solid #919191 !important;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 18px;
}
.prev-btn i.fa.fa-eye {
  font-size: 16px;
}
.modal-open .modal {
  overflow-x: hidden !important;
  /* overflow-y: hidden !important; */
}
.eduction-heading {
  color: #333;
  font-size: 35px;
  font-weight: 300 !important;
  margin-bottom: 20px;
}
.tips-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600 !important;
}
.resume-content-pd {
  padding-top: 9%;
}
form label {
  margin-bottom: 5px;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
  display: none;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred,
.ck.ck-editor__editable.ck-focused {
  height: 200px;
  border: none !important;
  border: 1px solid #ccc !important;
}
.dotted-btn {
  border: 1px dashed var(--blue) !important;
  padding: 14px !important;
  border-radius: 0 !important;
}
.f-18 {
  font-size: 18px;
}
.f-12 {
  font-size: 12px;
}
.w-100 {
  width: 100%;
}
.modal-open {
  overflow: auto !important;
}
.modal-open .modal {
  pointer-events: none;
}
.active-resume-btn {
  padding: 3px;
  transition: all 0.3s ease-in-out;
}
/* react modal css */
.close-btn {
  position: relative;
min-height: 40px;
display: block;
}
.close-btn .close {
    position: absolute;
    right: 25px;
    top: 10px;
}
.close-btn .close {
  opacity: 1;
}
/* custom check css */
.custom-check-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.custom-check-container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-check-container .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--white);
  border: 1px solid var(--light-dark);
}
.custom-check-container:hover input[type="checkbox"] ~ .checkmark {
  background-color: var(--white);
}
.custom-check-container input[type="checkbox"]:checked ~ .checkmark {
  background-color: var(--white);
  border: 1px solid var(--blue);
}
.custom-check-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.custom-check-container input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}
.custom-check-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid var(--blue);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
/* end a custom checkbox */
.search-test .btn-outline-danger, .search-test .btn-outline-danger:hover{
    color: var(--blue);
    border-color: #dc3545;
    border: none;
    font-size: 18px;
    background-color: transparent !important;
}
/* Create a custom radio input */
.custom-radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none;
  user-select: none;
}
.custom-radio-container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom-radio-container .checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--white);
  border-radius: 50%;
  border: 1px solid var(--light-dark);
}
.custom-radio-container input[type="radio"]:checked ~ .checkmark-radio {
  background-color: var(--white);
  border: 1px solid var(--blue);
}
.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--blue);
}
.custom-radio-container input[type="radio"]:checked ~ .checkmark-radio:after {
  display: block;
}
/* end a custom radio input */
.modal-backdrop.show {
  opacity: 0.12 !important;
}
.text-inherit {
  text-transform: inherit;
}
.custom-pd-left {
  padding-left: 4%;
}
.custom-pd-right {
  padding-right: 4%;
}
.width-btn {
  min-width: 160px;
}
/* add remove list */
.home-btn {
  width: 330px;
  padding: 15px;
}
.flow-icon {
  text-align: center;
}
.wd-180 {
  min-width: 180px;
}
.upload-photo-btn {
    padding: 9px 10px !important;
    font-size: 13px !important;
    display: none !important;
}
.d-table {
  display: table;
  width: 100%;
}
.d-table-cell {
  display: table-cell;
}

/* range slider */
.MuiSlider-root {
  color: var(--blue);
}
/* new template css */
.temp-4 {
  padding: 25px;
}
.top-section {
  padding-top: 30px;
  padding-bottom: 20px;
}
.parent-container {
  padding-left: 30px;
  padding-right: 30px;
}
.name-title .title-name{
  font-weight: 700 !important;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 0px;
  font-size: 34px;
}
.parent-container {
  display: flex;
}
.parent-left {
  padding-right: 25px;
  width: 34%;
}

.parent-col:nth-of-type(2) {
  padding-left: 15px;
}
.resume-ml {
  margin-left: -30px;
  padding-left: 30px;
  margin-right: -25px;
}
.pd-bg-section {
  padding: 30px;
  padding-left: 0px;
}
.parent-container .border-left {
  border-left: 2px solid #000 !important;
}
.pt-30 {
  padding-top: 30px;
}
.socail-info {
  word-break: break-all;
}
.resume-preview.text-right {
  text-align: left !important;
}
/* .resume-content-box {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
} */
.summay-box,
.socail-info,
.work-history-box,
.language-box,
.skill-list {
  margin-bottom: 20px;
}
/* template one */
.resume-content-one {
  display: flex;
}
.resume-one-right {
  width: 40%;
  background-color: var(--resume-bg);
  padding: 3%;
}
.resume-one-left {
  width: 60%;
  padding: 3%;
}
/* template three */
.temp-3 .socail-info .resume-heading .heading-resume {
  display: none;
}
.temp-3 .socail-info {
  margin-bottom: 0px;
  margin-top: 10px;
}
.top-fix-box {
  padding: 3% 25px;
  padding-left: 45px;
}

.temp-3 .resume-heading .heading-resume {
  position: relative;
}
.temp-3 .resume-heading:before .heading-resume:before {
  content: "";
  left: -38px;
  top: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #ccc;
  position: absolute;
  border-radius: 50%;
  background: #fff;
}
/* .temp-3 .language-box {
  width: 50%;
} */
.percent-clr {
    width: 11%;
}
.temp-3 .skill-list-content {
  column-count: 2;
}
/* temp 1 */
.resume-content-one {
  padding-left: 0px;
  padding-right: 0px;
}
/* .preview-inner {
  pointer-events: none;
} */
/* .resume-temp:hover {
  opacity: 0.5;
} */
.final-resume-box .preview-inner {
  pointer-events: auto;
}
/* resume format */
.color-formatting .resume-color-list {
    background: #fff;
    top: 100%;
    position: absolute;
    padding-top: 6px;
    left: 0;
    box-shadow: 0 0 10px rgb(88 88 95 / 20%);
    border: 1px solid #d7dde2;
    min-width: 196px;
}
.color-formatting .custom-col{
  position: relative;
  z-index: 999;
}
/* .color-formatting {
    position: absolute;
    bottom: 15px;
} */
.color-formatting .color-selector-radio {
    text-align: center;
}
/* .final-resume-box .resume-table, .final-resume-box .preview-inner {
  min-height: 800px;
} */
.final-resume-box .resume-content-box .parent-col, .final-resume-box .resume-content-three{
  min-height: 800px;
}
.check-vd-input {
  position: relative;
}
.test-custom-text {
  position: absolute;
  display: inline-block;
  margin-top: 5px;
  color: var(--blue);
}
p{
  word-break: break-word;
}
/* api loader */
.sync-loader {
  position: relative;
  min-height: 380px;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.disabled, .btn:disabled {
    opacity: 1 !important;
}
.btn{
  border-radius: 0px !important;
}
.form-control:focus{
  box-shadow: none !important;
}
.form-control{
  border-radius: 0 !important;
  height: calc(1.65em + 0.75rem + 2px) !important;
      font-size: 14px !important;
}

.btn-white {
    color: #fff !important;
    border-color: #fff !important;
}
.btn-white:hover{
  background-color: transparent !important;
}
.form-group {
    margin-bottom: 1.6rem !important;
}
/* .mleft-3 {
    margin-left: -10px;
    padding-right: 0px !important;
} */
.mt-10 {
    margin-top: 6rem;
}
.f-16 {
    font-size: 16px;
    color: #000;
}
/* toggle css */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 23px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
     height: 19px;
    width: 19px;
    left: 2px;
    bottom: 2px;
  background-color: white;
  transition: .4s;
}
.switch.active .slider::before {
    transform: translateX(15px);
    left: 6px;
}

.switch.active .slider {
  background-color: #27616e;
  
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #27616e;
}

.switch.active + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.gap-1{
  grid-gap:0.5rem;
  gap:0.5rem;
}
.toggle-btn-form {
    border: none;
    outline: none !important;
    background: transparent;
}
.row.resume-contact-form.hide {
    display: none;
}
.row.resume-contact-form.show {
    display: flex;
}
.MuiPaper-root.MuiDialog-paper {
    border-radius: 0;
    padding: 25px;
    text-align: center;
}
.MuiDialogActions-root button.btn.btn-secondary {
    background: #FFA500;
    border: none;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1180px !important;
  }
}
@media screen and (min-width: 1000px) {
  .resume-form-page .col-lg-6.custom-col-7 {
    flex: 0 0 55%;
    max-width: 55%;
  }
  .resume-form-page .col-lg-6.custom-col-5 {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .col-lg-8.col-custom {
    flex: 0 0 70.666667%;
    max-width: 70.666667%;
  }
  /* reusme small css */
  .resume-zoom .top-heading {
    padding: 32px;
    font-size: 15px;
    line-height: 13px;
    padding-top: 30px;
    min-height: 11px;
  }
  .resume-zoom .resume-heading .heading-resume {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .resume-zoom .resume-template-box {
    font-size: 5px;
  }
  .resume-zoom .percent-clr {
    width: 10px;
  }
  .resume-zoom ul {
    padding-left: 10px !important;
    margin-bottom: 5px;
  }
  .resume-zoom .pt-5 {
    padding-top: 5px !important;
  }
  .resume-zoom hr {
    margin-top: 5px;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  .resume-zoom .clr-dark {
    padding: 5px;
  }
  .resume-zoom .resume-icon {
    padding-right: 2px;
    display: inline-block;
  }
  .resume-zoom .socail-info p {
    position: relative;
    padding-left: 10px;
  }
  .resume-zoom.preview-box .socail-info p {
    padding-left: 15px;
  }
  .resume-zoom .resume-template-box {
    padding: 8px 3%;
  }
  .resume-zoom .pt-4 {
    padding-top: 5px !important;
  }
  .resume-zoom .clr-dark {
    padding: 6px !important;
  }
  .resume-zoom .td-left {
    padding-right: 10px !important;
  }
  .resume-zoom .mb-2 {
    margin-bottom: 0px !important;
  }
  .resume-zoom .resume-third-temp .resume-heading:after .heading-resume:after {
    top: -1px;
    height: 10px;
  }
  .resume-zoom .resume-third-temp .resume-heading .heading-resume {
    padding-left: 10px;
  }
  /* template two */
  .resume-zoom .h1,
  .resume-zoom h1 {
    font-size: 10px;
    margin-bottom: 5px;
    word-break: break-word;
  }
  .resume-zoom .Name-heading {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .resume-zoom .Name-heading:after {
    width: 15px;
    height: 2px;
  }
  .resume-zoom .resume-icon.box {
    padding-right: 0px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    margin-right: 2px;
    position: absolute;
    left: 0;
  }
  .resume-zoom .resume-sec-temp .socail-info p {
    margin-bottom: 5px !important;
    padding-left: 14px;
  }
  .resume-zoom .resume-sec-temp .mb-5 {
    margin-bottom: 1rem !important;
  }
  /* third template css */
  .resume-zoom .social-info-third p {
    margin-bottom: 2px;
  }
  .resume-zoom .resume-heading-third:after {
    top: -1px;
    height: 10px;
  }
  .resume-zoom .resume-heading-third {
    padding-left: 10px;
  }
  .resume-zoom .summay-box,
  .resume-zoom .skill-list,
  .resume-zoom .work-history-box,
  .resume-zoom .socail-info {
    margin-bottom: 10px !important;
  }
  .resume-zoom .resume-third-temp .mb-5 {
    margin-bottom: 10px !important;
  }
  /* changes css */
  .footer-bootom .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-screen .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  /* new resume template css */
  .template-section .resume-zoom .top-section,
  .resume-preview .resume-zoom .top-section {
    padding-bottom: 15px;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .template-section .resume-zoom .name-title .title-name,
  .resume-preview .resume-zoom .name-title .title-name {
    font-size: 16px;
    margin-bottom: 0px;
    padding-bottom: 7px;
  }
  .template-section .resume-zoom .resume-heading .heading-resume {
    font-size: 9px;
    font-weight: 700 !important;
  }
  .template-section .resume-zoom .parent-right {
    width: 66%;
    padding-left: 15px;
  }
  .template-section .resume-zoom .parent-left,
  .resume-preview .resume-zoom .parent-left {
    width: 34%;
    padding-right: 0px;
  }
  .template-section .resume-zoom .pd-bg-section,
  .resume-preview .resume-zoom .pd-bg-section {
    padding: 15px;
    padding-left: 0px;
  }
  .template-section .resume-zoom .resume-ml,
  .resume-preview .resume-zoom .resume-ml {
    margin-left: -15px;
    padding-left: 10px;
    margin-right: -15px;
  }
  .template-section .resume-zoom .parent-container .border-left,
  .resume-preview .resume-zoom .parent-container .border-left {
    border-left: 1px solid #000 !important;
  }
  .template-section .resume-zoom .parent-container,
  .resume-preview .resume-zoom .parent-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .template-section .resume-zoom .resume-template-box,
  .resume-preview .resume-zoom .resume-template-box {
    font-size: 7.5px;
    font-weight: 500;
  }
  .template-section .resume-zoom .pt-30,
  .resume-preview .resume-zoom .pt-30 {
    padding-top: 15px;
  }
  .template-section .resume-zoom .resume-table.pb-5,
  .resume-preview .resume-zoom .resume-table.pb-5 {
    padding-bottom: 10px !important;
  }
  .template-section .resume-zoom .resume-template-box,
  .resume-preview .resume-zoom .resume-template-box {
    padding: 8px;
  }
  .template-section .resume-zoom .resume-sec-temp .socail-info p,
  .resume-preview .resume-zoom .resume-sec-temp .socail-info p {
    margin-bottom: 3px !important;
    padding-left: 0px;
  }
  .resume-preview .resume-zoom .resume-heading .heading-resume {
    font-size: 8px;
  }
  .resume-preview .resume-zoom .parent-right {
    padding-left: 10px;
    width: 66%;
  }
  .resume-zoom .resume-content-box {
    display: flex;
    justify-content: center;
    /* height: 100%;
    overflow: hidden; */
    padding-left: 15px;
    padding-right: 15px;
  }
  .resume-preview .resume-zoom .resume-template-box {
    height: 630px;
    overflow: hidden;
    overflow-y: auto;
  }
  .resume-preview .resume-zoom .preview-inner {
    height: auto;
  }
  .resume-zoom .temp-3 .socail-info {
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
  .resume-zoom .temp-3 .top-fix-box {
    padding: 3% 8px;
    padding-left: 15px;
  }
  .resume-zoom .temp-3 .resume-content-three {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .resume-zoom .temp-3 .resume-heading:before .heading-resume:before {
    content: "";
    left: -15px;
    height: 8px;
    width: 8px;
  }
  .resume-temp .resume-zoom .preview-inner {
    height: 432px;
    overflow: hidden;
  }
  .resume-temp .resume-zoom .resume-template-box .preview-inner {
    height: 454px;
  }
  .preview-box .resume-content-one .parent-col:nth-of-type(2),
  .preview-box .resume-content-one .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
  .resume-zoom.preview-box .resume-icon svg {
    width: 9px;
    height: 9px;
  }

  .resume-zoom.preview-box .resume-content-one .parent-col:nth-of-type(2) {
    padding-right: 10px;
    padding-left: 10px;
  }
  .resume-zoom.preview-box .resume-content-three {
    margin-top: 0px;
    margin-left: 0px;
  }
  .resume-zoom.preview-box .resume-content-three .parent-col:nth-of-type(2) {
    padding-top: 0px !important;
  }
  .resume-zoom.preview-box
    .temp-4-pd
    .resume-content-box
    .parent-col:nth-of-type(1) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1150px) {
  .resume-info-container h6 {
    font-size: 15px;
  }
  .resume-info {
    font-size: 10px;
  }
  .doc-Strength-label {
    font-size: 10px;
  }
  .doc-strength-value {
    width: 30px;
    height: 16px;
    line-height: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
}
@media screen and (max-width: 1000px) {
  .bg-double:after {
    display: none;
  }
  .resume-preview.p-5 {
    padding: 0px !important;
  }
  .pre-btn.text-right {
    display: none;
  }
  .screen-left {
    padding-left: 0%;
    padding-right: 0%;
  }
  .right-box-screen {
    justify-content: center;
    margin-bottom: 30px;
    min-height: 430px !important;
  }
  .screen-right {
    padding-left: 0%;
    padding-top: 0px;
  }
  .condition-box {
    padding-bottom: 20%;
  }
  .progress-section {
    display: flex;
    flex-wrap: wrap;
  }
  .progress-section .step {
    margin-bottom: 7px;
  }
  .resume-contact-section .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .resume-preview.text-right {
    /* text-align: center !important; */
    margin-top: 30px;
  }
  .pre-btn.text-center {
    padding-left: 0px !important;
  }
  .resume-preview img {
    height: auto;
  }
  .custom-pd-right {
    padding-right: 0;
  }
  .custom-pd-left {
    padding-left: 0;
  }
  .brand-logo a {
    margin-bottom: 15px;
    display: inline-block;
  }
  .mob-show {
    display: block;
  }
  .mob-hide {
    display: none !important;
  }
  .summary-box:after,
  .summary-box:before {
    display: none;
  }
  .extra-section-preview {
    padding-top: 10%;
  }
  .custom-add-section-list {
    padding-top: 10%;
  }
  .template,
  .formate {
    width: auto;
  }
  .sidebar-link {
    width: 100%;
    margin-bottom: 30px;
  }
  .main-resume {
    margin: 0px 0%;
  }
  .resume-zoom.pl-5 {
    padding-left: 0px !important;
  }
  /* resume slider zoom property start */
  .resume-slide-box .resume-zoom .top-heading {
    padding: 7px;
    font-size: 8px;
    line-height: 4px;
  }
  .resume-slide-box .resume-zoom .resume-heading .heading-resume {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    font-size: 5px;
  }
  .resume-slide-box .resume-zoom .percent-clr {
    width: 5px;
    height: 1.2px;
  }
  .resume-slide-box .resume-zoom ul {
    padding-left: 10px !important;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .pt-5 {
    padding-top: 5px !important;
  }
  .resume-slide-box .resume-zoom hr {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .clr-dark {
    padding: 5px;
  }
  .resume-slide-box .resume-zoom .resume-icon {
    padding-right: 2px;
    display: inline-block;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    padding: 8px 3%;
  }
  .resume-slide-box .resume-zoom .pt-4 {
    padding-top: 5px !important;
  }
  .resume-slide-box .resume-zoom .clr-dark {
    padding: 6px !important;
  }
  .resume-slide-box .resume-zoom .td-left {
    padding-right: 10px !important;
  }
  .resume-slide-box .resume-zoom .mb-2 {
    margin-bottom: 0px !important;
  }
  /* template two */
  .resume-slide-box .resume-zoom .h1,
  .resume-slide-box .resume-zoom h1 {
    font-size: 8px;
    margin-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .Name-heading {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .resume-slide-box .resume-zoom .Name-heading:after {
    width: 15px;
    height: 2px;
  }
  .resume-slide-box .resume-zoom .resume-icon.box {
    padding-right: 0px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    margin-right: 2px;
  }
  .resume-slide-box .resume-zoom .resume-sec-temp .socail-info p {
    margin-bottom: 3px !important;
  }
  .resume-slide-box .resume-zoom .resume-sec-temp .mb-5 {
    margin-bottom: 1rem !important;
  }
  /* third template css */
  .resume-slide-box .resume-zoom .social-info-third p {
    margin-bottom: 2px;
  }
  .resume-slide-box .resume-zoom .resume-heading-third:after {
    top: -1px;
    height: 10px;
  }
  .resume-slide-box .resume-zoom .resume-heading-third {
    padding-left: 10px;
  }
  .resume-slide-box .resume-zoom .summay-box,
  .resume-slide-box .resume-zoom .skill-list {
    margin-bottom: 10px !important;
  }
  .resume-slide-box .resume-zoom .resume-third-temp .mb-5 {
    margin-bottom: 10px !important;
  }
  /* resume slider zoom property end */
  /* language slider */
  .lang-range-slider {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    margin-bottom: 10%;
  }
  /* payment css */

  .payment-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .plan-box.left-section.sm-sec,
  .plan-box.left-section.middle-section {
    width: 100%;
    margin-bottom: 20px;
    min-height: auto;
  }
  /* customer login css */
  .custom-table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 2px;
  }
  .account-content-box {
    border-left: none;
    padding-left: 0;
    margin-top: 20px;
  }
  .account-info-list li .account-info-item,
  .account-info-list li .account-info,
  .account-info-list li .link {
    min-width: 100%;
    display: block;
  }
  .subscription-box {
    flex-direction: column;
  }
  .contactus-info {
    border-left: none;
    padding: 0;
    border-left: 0;
    border-top: 1px solid #9b9b9b;
    padding-top: 20px;
    margin-top: 20px;
  }
  .subscription-box {
    background-color: #f4f5fb;
    padding: 1.5rem 1rem;
  }
  .resume-temp .resume-zoom .preview-inner {
    height: 860px;
  }
  .resume-preview .resume-zoom .preview-inner {
    height: 790px;
    overflow: hidden;
  }
  .left-box-screen,
  .right-box-screen {
    min-height: calc(100vh - 620px) !important;
  }
  .resume-preview .pl-3 {
    padding-left: 0px !important;
  }
  .width-btn {
    min-width: auto;
  }
  .mpd-5 {
    margin-top: 30px;
  }
  .resume-slide-box .resume-content-box .parent-col {
    min-height: 300px;
  }
  .resume-icon svg {
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 767px) {
  .resume-temp:hover:after,
  .resume-temp.mobile-active:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    z-index: 9;
    /* outline: 3px solid red; */
  }
  .resume-temp.active {
    outline: 3px solid #ffa62b;
  }
  .resume-temp.mobile-active .resume-select-btn {
    opacity: 1;
    z-index: 10;
  }
  .site-btn--box {
    width: 100% !important;
    max-width: 100% !important;
  }

  .edu-summ-btn {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin: 0px;
  }

  .site-btn {
    width: 100% !important;
    max-width: 100%;
  }

  .manage--width {
    width: 100%;
    max-width: 100% !important;
  }
  .static--btn-box {
    position: fixed;
    bottom: 0;
    padding: 10px;
    background: white;
    z-index: 10;
    left: 0px;
    width: 100%;
  }
  .flex-direction {
    flex-direction: column-reverse;
  }
  .footer-choose-btn .site-btn {
    padding: 12px 12px !important;
  }
  .editor-wrapper {
    border: none;
  }
  .list-editor,
  .draft-editor-box {
    width: 100%;
    margin-bottom: 25px;
  }
  .list-editor {
    border: 1px solid var(--light-grey);
  }
  .para-toolbar a {
    padding: 0px;
    font-size: 14px;
    margin: 4px !important;
  }
  .paragroup-item {
    padding: 40px 20px 0 20px;
  }
  .site-btn {
    padding: 12px 25px !important;
  }
  .modal.show .more-info-modal {
    margin-top: 1.75rem !important;
  }
  .more-info-modal .modal-body {
    padding: 40px 25px;
  }
  .custom-modal-footer {
    padding: 30px 30px;
    padding-top: 0px;
  }
  .fiter-search-left,
  .fiter-search-right {
    width: 100%;
  }
  .width-btn {
    min-width: auto;
  }
  .drop-down-template {
    margin-bottom: 5px;
    padding: 13px 8px !important;
    justify-content: center;
  }
  .footer-top {
    padding-bottom: 40px;
  }
  .bottom-fix-toolbar {
    padding: 15px !important;
  }
  .popover-box-inner {
    border-radius: 0;
    width: 270px;
  }
  /* customer admin css */
  .create-resume-link {
    margin-top: 15px;
  }
  .h1,
  h1 {
    font-size: 1.5rem !important;
  }
  /* language slider */
  .range-text {
    font-size: 12px;
  }
  .lang-range-slider {
    width: 90%;
  }
  .proficency-range .MuiSlider-markLabel {
    top: -20px !important;
  }
  .range-text .label-value {
    left: -35px;
    top: 50px;
  }
  .resume-zoom.pl-3 {
    padding-left: 0px !important;
  }
  /* .resume-slide-box .resume-heading {
    font-size: 8px !important;
} */

  .resume-slide-box .resume-preview .resume-zoom .resume-template-box {
    height: 213px;
    overflow: hidden;
  }
  .resume-slide-box .resume-preview .resume-zoom .preview-inner {
    height: 385px;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    padding: 8px;
  }
  .resume-slide-box .resume-zoom .resume-heading .heading-resume {
    font-size: 14px !important;
  }
  .resume-slide-box .resume-zoom .resume-template-box {
    font-size: 10px;
  }
  .resume-slide-box .resume-zoom .name-title .title-name {
    font-size: 18px !important;
  }
  .resume-slide-box .resume-zoom .top-fix-box {
    padding-left: 35px;
  }
  .resume-slide-box .resume-zoom .top-heading {
    padding: 14px;
  }
  .parent-col:nth-of-type(2) {
    padding-left: 15px;
  }
  .formating-box {
    left: -70%;
  }
  .resume-slide-box .resume-zoom .top-section {
    padding: 0px !important;
    padding-bottom: 0px !important;
  }
  .resume-slide-box .resume-preview .pl-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .resume-slide-box
    .styleTempDBTen
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right,
  .resume-slide-box
    .styleTempDBEleven
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right {
    padding-left: 34%;
  }
  .scroll-resume-box .styleTempDBNine .dummy-img-box {
    top: 10px;
  }
  /* active language slider */
  .active-slide .paragroup-item {
    padding: 20px 20px 0 30px;
  }
  .active-slide .lang-range-slider {
    width: 100%;
  }
  .active-slide .para-toolbar {
    top: 8px;
  }
  .cart-modal-footer h4 {
    font-size: 17px !important;
  }
  .header.border-bottom {
    padding: 0px 10px;
  }
  .brand-logo img {
    height: 35px;
  }
  .popup-body .grid.grid-cols-2 {
    padding: 30px 30px;
  }
  .heading,
  .chosse-page-title {
    font-weight: 600 !important;
  }
  .exp-content-box {
    margin-top: 0vh;
    padding-top: 2rem !important;
  }
  .exp-content-box .mb-5 {
    margin-bottom: 35px !important;
  }
  .mob-col {
    width: 50% !important;
  }
  .resume-template-box ul {
    padding-left: 9px;
    word-wrap: break-word;
  }
  .resume-heading {
    font-size: 12px !important;
    margin-bottom: 9px;
  }
  .paragraph {
    font-size: 8px;
  }
  div#socialInfo p {
    min-height: 10px;
    word-break: break-all;
  }
  .resume-icon {
    padding-right: 5px;
  }
  .styleTempDBNine .parent-col:nth-of-type(1) .parent-right .name-title {
    padding-left: 8px;
    padding-top: 6px;
    margin-bottom: 0px;
  }
  .template-section .styleTempDBNine .dummy-img {
    width: 103px !important;
  }
  .resume-zoom.styleTempFive .profile-img-box,
  .resume-zoom.styleTempFour .profile-img-box {
    margin-bottom: 4px;
  }
  .choose-template-section.pb-5 {
    padding-bottom: 67px !important;
  }
  .resume-select-btn .site-btn {
    min-width: 125px;
    font-size: 14px !important;
    padding: 5px 4px !important;
    box-shadow: none !important;
    position: relative;
  }
  .resume-temp .resume-select-btn {
    min-width: 110px;
  }
  .resume-preview {
    display: none;
  }
  .home-btn {
    width: 100%;
    max-width: 100% !important;
    position: fixed;
    left: 0px;
    font-size: 20px;
    bottom: 0px;
    margin-bottom: 0px;
    background-color: #fff;
  }
  .home-btn .custm-btn {
    background-color: #ff6d05 !important;
    border-radius: 0;
    font-size: 14px;
  }
  .site-btn {
    width: 100%;
  }
  .switch.active .slider::before {
    left: 0px !important;
  }
  .exp-screen-section,
  .choose-template-section {
    min-height: calc(100vh - 160px) !important;
  }
  .step-numbering.mt-1.mb-1 {
    margin-top: 1rem !important;
  }
  .upload-img-input {
    width: 100%;
    height: 225px;
    min-height: 225px;
  }
  .text-right.modal-image-btn {
    text-align: left !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .modal-image-btn .btn {
    max-width: 100% !important;
  }
  h2.heading.text-white.bold {
    font-size: 36px;
  }
  .modal-content-box .modal-body {
    padding: 1rem 1rem;
  }
  .custom-modal-footer .pd-0 {
    padding-right: 10px !important;
  }
  .summary-preview {
    min-height: calc(100vh - 60px) !important;
  }
  .language-screen .pl-0,
  .language-screen .px-0 {
    padding-left: 15px !important;
  }
  .language-screen .pr-2,
  .language-screen .px-2 {
    padding-right: 15px !important;
  }
  .range-text .label-value {
    left: 43%;
  }
  .active-slide .paragroup-item.pd-custom .para-toolbar {
    top: 7px;
    width: 20px;
    height: 20px;
    font-size: 11px;
  }
  .label-name {
    width: 100%;
    overflow: hidden;
    width: 48px;
    text-overflow: ellipsis;
  }
  .proficency-range.mt-4 {
    margin-top: 5px !important;
  }
  .col-sm-12.langg-hd {
    margin-top: 10px;
  }
  .add-section-list .custom-check-container .checkmark {
    position: absolute;
    top: 2px;
  }
  .signature-preview h2.text-white.semi-bold {
    font-size: 28px;
    margin-bottom: 13px;
  }
  .signature-preview .heading.text-white.bold {
    font-size: 18px !important;
  }
  .tab-header button {
    color: #000;
    font-size: 14px;
  }
  .custom-col.formate {
    display: none;
  }
  .bottom-fix-toolbar {
    top: 0px;
  }
  .drop-down-template {
    padding-right: 18px !important;
  }
  .more-color-dropdown:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
  }
  .more-color-dropdown {
    top: 49%;
  }
  .final-structure-container {
    padding-top: 0px !important;
  }
  .after-login-header .container {
    padding: 10px 0px;
  }
  .plan-box.right-section {
    width: 100%;
  }
  .side-content {
    width: 100%;
  }
  h4.tab-title.mb-4 {
    font-size: 1.3rem;
  }
  .tab-list-content a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 3px;
  }
  .list-view-tesume td,
  .list-view-tesume th {
    vertical-align: top;
  }
  /* iphone responsive */
  .resume-template-box {
    zoom: 1;
  }
  .resume-heading {
    font-size: 4px !important;
    margin-bottom: 3px;
  }
  .template-grid h1.name-title {
    font-size: 9px !important;
  }
  .template-grid .styleTempDBEight h1.name-title,
  .template-grid .styleTempDBTen h1.name-title,
  .template-grid .styleTempDBEleven h1.name-title {
    font-size: 9px !important;
    margin-bottom: 0px;
  }
  .template-grid
    .styleTempDBSix
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .resume-zoom .styleTempDBSix .parent-right .name-title {
    font-size: 8px !important;
    margin-bottom: 0px;
    padding-top: 12px;
  }

  .template-grid .styleTempDBSeven .dummy-img,
  .resume-preview .styleTempDBSeven .dummy-img,
  .resume-preview .styleTempDBNine .dummy-img {
    height: 35px !important;
    width: 35px !important;
  }
  .template-section .resume-zoom.styleTempDBSix.resume-zoom .dummy-img,
  .resume-zoom.styleTempDBSix .resume-preview .resume-zoom .dummy-img {
    height: 35px !important;
    width: 35px !important;
  }
  .template-section .resume-zoom .dummy-img,
  .resume-preview .resume-zoom .dummy-img {
    height: 35px;
  }
  .styleTempDBEleven .temp-4-pd .parent-left {
    padding: 10px !important;
  }

  .styleTempDBEight .parent-col:nth-of-type(2),
  .styleTempDBEight .parent-col:nth-of-type(2) {
    padding-top: 30px !important;
  }
  .choose-template-section .temp-4-pd .parent-col:nth-of-type(2) {
    padding-right: 7px;
  }
  .choose-template-section .parent-col:nth-of-type(2) {
    padding-left: 7px;
  }
  .styleTempDBSeven .top-section .parent-right,
  .styleTempDBEight .top-section .parent-right,
  .styleTempDBTen .top-section .parent-right,
  .styleTempDBEleven .top-section .parent-right {
    padding-top: 8px;
  }
  .resume-content-one .parent-col:nth-of-type(3),
  .temp-4-pd .parent-col:nth-of-type(3) {
    padding-left: 7px;
    padding-right: 7px;
  }
  .resume-preview-box .resume-icon svg {
    width: 2px;
    height: 2px;
  }
  div#socialInfo p {
    min-height: 0px;
    word-break: break-all;
    margin-bottom: 0px !important;
  }
  .resume-zoom .styleTempDBEight .dummy-img,
  .template-section .styleTempDBEight .dummy-img {
    height: 45px !important;
  }
  .resume-preview-box .summay-box,
  .socail-info,
  .work-history-box,
  .language-box,
  .skill-list {
    margin-bottom: 7px;
  }
  .styleTempDBSix .parent-col:nth-of-type(1) .parent-right {
    padding-left: 7px;
  }
  .styleTempDBSix .parent-col:nth-of-type(3) {
    padding-top: 8px !important;
  }
  .styleTempDBSix .resume-content-box .parent-col:nth-of-type(2) {
    padding-top: 15px !important;
  }
  .styleTempDBSix .parent-col:nth-of-type(1) .parent-left .profile-pic {
    top: 15px;
  }
  .skill-list-content {
    margin-bottom: 0px;
  }
  .template-section .resume-preview-box .resume-temp {
    min-height: 213px;
  }
  .resume-slide-box .resume-preview .resume-zoom .preview-inner {
    height: 213px !important;
  }
  .template-section .resume-preview-box .resume-temp .resume-template-box,
  .template-section
    .resume-preview-box
    .resume-temp
    .resume-template-box.temp-3 {
    height: 218px;
  }
  .template-section .resume-zoom .temp-3 .dummy-img,
  .resume-preview .resume-zoom .temp-3 .dummy-img,
  .template-section .styleTempDBNine .dummy-img {
    height: 40px !important;
    width: 40px !important;
  }
  .styleTempDBTwelve .profile-pic .addNew .dummy-img {
    height: 25px !important;
    width: 25px !important;
  }
  .template-section .styleTempDBTen .temp-4-pd .parent-left {
    padding-right: 0px !important;
    padding: 6px !important;
  }
  .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(1) .parent-right,
  .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(1) .parent-right {
    padding-left: 29%;
  }
  .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 35px !important;
  }
  .resume-zoom.styleTempThree .temp-3 .mb-3.py-2.px-4.name-bg {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .resume-zoom .resume-template-box.temp-3 .socail-info.resume-social-info p {
    font-size: 3px;
    padding-left: 8px;
  }
  .temp-3 .socail-info {
    margin-top: 5px;
  }
  .resume-zoom .resume-template-box.temp-3 .profile-img-box {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .parent-col:last-child {
    padding-top: 8px !important;
  }
  .styleTempOne .resume-section .paragraph,
  .styleTempOne .resume-section .paragraph1,
  .styleTempThree .resume-section .paragraph,
  .styleTempThree .resume-section .paragraph1 {
    padding-left: 15px;
  }
  .resume-zoom.styleTempDBTen .dummy-img,
  .resume-zoom.styleTempDBEleven .dummy-img {
    height: 35px !important;
  }
  .styleTempDBEleven .postition-bg-style.fade-bg {
    height: 128px;
    width: 128px;
  }
  .template-grid .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 30px !important;
  }
  .resume-zoom.styleTempThree .profile-img-box {
    padding-right: 0px !important;
  }
  .template-grid .styleTempThree .contact-info-box {
    padding-left: 0px !important;
  }
  .template-grid .styleTempThree .mb-3.name-bg {
    margin-bottom: 0px !important;
  }
  .template-section .resume-zoom.styleTempThree .temp-3 .dummy-img {
    height: 25px !important;
    width: 25px !important;
  }
  .choose-template-section .percent-box {
    margin-bottom: 2px;
  }
  .choose-template-section p.percent.mb-1 {
    margin-bottom: 2px !important;
  }
  .styleTempDBSeven .top-section .parent-right,
  .styleTempDBEight .top-section .parent-right,
  .styleTempDBTen .top-section .parent-right,
  .styleTempDBEleven .top-section .parent-right {
    padding: 6px;
  }
  .template-grid
    .styleTempDBTwelve
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    font-size: 9px !important;
    font-weight: 600 !important;
    padding-left: 7px;
  }
  .styleTempDBTwelve .profile-pic .dummy-img-box {
    padding-left: 8px;
  }
  .styleTempDBTwelve .top-section.parent-container {
    padding: 7px 0px !important;
    padding-bottom: 7px !important;
  }
  .choose-template-section .styleTempThree .name-bg {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .choose-template-section
    .styleTempDBTwelve
    .temp-4-pd
    .parent-col:nth-of-type(2),
  .choose-template-section
    .styleTempDBTwelve
    .temp-4-pd
    .parent-col:nth-of-type(3) {
    padding-top: 9px !important;
  }
  .choose-template-section
    .template-grid
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .choose-template-section
    .resume-zoom
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    font-size: 9px !important;
  }
  .choose-template-section .styleTempDBSeven .dummy-img-box,
  .styleTempDBNine .dummy-img-box {
    transform: translate(-50%, 0px);
    top: 7px;
  }
  .choose-template-section .styleTempDBSeven .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
  .choose-template-section .styleTempDBNine .parent-col:nth-of-type(2) {
    padding-top: 40px !important;
  }
  .template-grid
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .resume-zoom
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    font-size: 8px !important;
  }
  .choose-template-section .styleTempOne .top-fix-box,
  .choose-template-section .styleTempTwo .top-fix-box,
  .choose-template-section .styleTempThree .top-fix-box,
  .choose-template-section .styleTempFour .top-fix-box {
    padding-left: 8px !important;
    padding-right: 8px !important;
    padding-top: 8px !important;
  }
  .choose-template-section .resume-zoom.styleTempOne .mb-3.py-2.styled-bg {
    padding-bottom: 0px !important;
  }
  .choose-template-section
    .resume-template-box.temp-3
    .parent-col.pt-4.pr-3.pl-3 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .template-grid .styleTempFour .resume-heading,
  .template-grid .styleTempTwo .resume-heading {
    padding: 2px;
    font-size: 3px !important;
    padding-bottom: 1px;
  }
  .template-grid .styleTempFour .profile-content .resume-social-info {
    padding-left: 12px;
  }
  .template-grid .styleTempFive .slide-bg-clr.top-fix-box {
    padding: 8px;
  }
  .template-grid .styleTempFive .top-fix-box {
    margin-bottom: 2px;
  }
  .template-grid .styleTempFour .resume-section .paragraph,
  .template-grid .styleTempFour .resume-section .paragraph1,
  .template-grid .styleTempFive .resume-section .paragraph,
  .template-grid .styleTempFive .resume-section .paragraph1 {
    padding-left: 15px;
  }
  .resume-pre-dialog .modal-content {
    height: 90vh !important;
    overflow-y: scroll;
  }
  form input,
  .check-vd-input input,
  form select {
    background-color: #fff !important;
    color: #000 !important;
  }
  form.resume-contact-form .custom-check-container .checkmark {
    top: -2px;
  }
  .modal-dialog.resume-pre-dialog h3 {
    font-size: 1.4rem;
  }
  /* final-resume-css */
  .final-resume-box .styleTempDBEight .dummy-img {
    height: 100px !important;
  }
  .final-resume-box .styleTempDBTwelve .profile-pic .addNew .dummy-img {
    height: 55px !important;
    width: 55px !important;
  }
  .final-resume-box .styleTempDBSix .dummy-img {
    height: 70px !important;
    width: 70px !important;
  }
  .final-resume-box .styleTempDBTwelve .profile-pic .dummy-img-box {
    padding-left: 15px;
  }
  .final-resume-box .resume-heading {
    font-size: 10px !important;
  }
  .final-resume-box .paragraph,
  .final-resume-box .resume-template-box ul {
    font-size: 8px !important;
    line-height: normal !important;
  }
  .final-resume-box .parent-col:nth-of-type(1) .parent-right {
    padding: 15px;
    padding-bottom: 0px;
  }
  .final-resume-box .temp-4-pd .parent-col:nth-of-type(3),
  .final-resume-box .temp-4-pd .parent-col:nth-of-type(2) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .final-resume-box .resume-icon svg {
    height: 8px;
    width: 8px;
  }
  .final-resume-box .styleTempDBEight .parent-col:nth-of-type(2),
  .final-resume-box .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2),
  .final-resume-box .styleTempDBEleven .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 70px !important;
  }
  .final-resume-box .upload-sign {
    line-height: normal;
    font-size: 8px;
  }
  .final-resume-box .styleTempDBTwelve .top-section.parent-container {
    padding: 15px 0px !important;
    padding-bottom: 15px !important;
  }
  .final-resume-box .styleTempDBTen .temp-4-pd .parent-left,
  .final-resume-box .styleTempDBEleven .temp-4-pd .parent-left {
    padding: 15px !important;
  }
  .final-resume-box .styleTempDBTen .dummy-img,
  .final-resume-box .styleTempDBEleven .dummy-img,
  .final-resume-box .styleTempDBTen .dummy-img-box .addNew,
  .final-resume-box .styleTempDBEleven .dummy-img-box .addNew,
  .final-resume-box .template-section .styleTempDBTen .dummy-img,
  .final-resume-box .template-section .styleTempDBEleven .dummy-img,
  .final-resume-box .resume-preview .styleTempDBTen .dummy-img,
  .final-resume-box .resume-preview .styleTempDBEleven .dummy-img {
    height: 80px !important;
  }
  .final-resume-box .styleTempDBEleven .postition-bg-style.fade-bg {
    height: 200px;
    width: 63%;
  }
  .final-resume-box
    .styleTempDBTen
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right,
  .final-resume-box
    .styleTempDBEleven
    .temp-4-pd
    .parent-col:nth-of-type(1)
    .parent-right {
    padding-left: 33%;
  }
  .final-resume-box .styleTempDBNine .dummy-img {
    height: 70px !important;
  }
  .final-resume-box .styleTempDBNine .temp-4-pd .parent-left {
    padding-top: 5px;
  }
  .final-resume-box
    .styleTempDBNine
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title {
    padding-left: 0px;
    padding-top: 10px;
  }
  .final-resume-box .styleTempDBSix .parent-right .name-title,
  .final-resume-box .styleTempOne .name-title {
    top: 5px;
    font-size: 1.3rem !important;
  }
  .final-resume-box .styleTempOne .profile-img-box {
    margin-bottom: 0px;
  }
  .final-resume-box .styleTempOne .top-fix-box,
  .final-resume-box .styleTempTwo .top-fix-box,
  .final-resume-box .styleTempThree .top-fix-box,
  .final-resume-box .styleTempFour .top-fix-box {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .final-resume-box .parent-col:nth-of-type(2) {
    padding-left: 15px;
  }
  .final-resume-box .styleTempOne .parent-col:nth-of-type(2) {
    padding-top: 15px !important;
  }
  .final-resume-box
    .styleTempDBSix
    .parent-col:nth-of-type(1)
    .parent-left
    .profile-pic {
    top: 44px;
  }
  .final-resume-box
    .styleTempDBSix
    .resume-content-box
    .parent-col:nth-of-type(2) {
    padding-top: 30px !important;
  }
  .final-resume-box .styleTempDBSeven .dummy-img,
  .final-resume-box .styleTempOne .dummy-img,
  .final-resume-box .styleTempFive .dummy-img {
    height: 70px !important;
    width: 70px !important;
  }
  .final-resume-box .styleTempDBSeven .dummy-img-box {
    top: 15px;
  }
  .final-resume-box .styleTempDBSeven .parent-col:nth-of-type(2) {
    padding-top: 45px !important;
  }
  .final-resume-box .styleTempOne .styled-bg {
    padding: 7px !important;
  }
  .final-resume-box .styleTempTwo .parent-col:nth-of-type(2),
  .final-resume-box .styleTempFour .parent-col:nth-of-type(2) {
    padding-top: 5px !important;
  }
  .final-resume-box .styleTempTwo .top-fix-box {
    padding-left: 6px !important;
    padding-right: 11px !important;
  }
  .final-resume-box .temp-3 .socail-info {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .final-resume-box .styleTempThree .top-fix-box {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .final-resume-box .styleTempThree .dummy-img,
  .final-resume-box .styleTempFour .dummy-img {
    height: 55px !important;
    width: 55px !important;
  }
  .final-resume-box div#socialInfo p {
    margin-bottom: 2px !important;
  }
  .final-resume-box .styleTempThree .profile-img-box {
    margin-bottom: 0px;
  }
  .final-resume-box .styleTempThree .mb-3.py-2.px-4.name-bg {
    margin-bottom: 0px !important;
  }
  .final-resume-box .styleTempThree .contact-info-box {
    padding-left: 15px;
  }
  .final-resume-box .styleTempThree .profile-img-box {
    margin-right: 15px;
  }
  .final-resume-box .resume-content-three .parent-col:nth-of-type(2) {
    padding: 15px;
  }
  .final-resume-box .socail-info p {
    position: relative;
    padding-left: 15px;
  }
  .final-resume-box .styleTempFour .top-fix-box {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .final-resume-box .styleTempFour .profile-img-box {
    margin-bottom: 0px;
  }
  .final-resume-box .styleTempFour .resume-section .paragraph,
  .final-resume-box .styleTempFour .resume-section .paragraph1,
  .final-resume-box .styleTempFive .resume-section .paragraph,
  .final-resume-box .styleTempFive .resume-section .paragraph1 {
    padding-left: 30px;
  }
  .final-resume-box .styleTempFive .slide-bg-clr.top-fix-box {
    padding: 15px;
  }
  .final-resume-box .styleTempFive .profile-img-box {
    margin-bottom: 0px;
  }
  .resume-slide-box .resume-preview {
    display: block;
  }
  .bottom-fix-toolbar .next-btn .site-btn {
    min-width: 100px;
    font-size: 8px !important;
    padding: 5px !important;
    font-weight: 600 !important;
  }
  .drop-down-template.pdc p {
    font-size: 8px !important;
  }
  .img-icon.template-icon img {
    height: 14px;
  }
  .drop-down-template .color-selector-radio {
    height: 15px;
    width: 15px;
  }
  .resume-slide-box {
    z-index: 3;
  }
  .drop-down-template p.mb-1.bold {
    font-size: 8px;
  }
  .drop-down-template {
    margin-bottom: 0px;
    padding: 2px 8px !important;
  }
  .custom-left-sec .custom-col {
    margin-right: 0px;
  }
  .bottom-fix-toolbar .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .bottom-fix-toolbar .custom-row {
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  .custom-left-sec,
  .custom-right-sec {
    width: 48%;
  }
  .custom-left-sec .bg-green,
  .custom-right-sec .bg-green {
    width: 100%;
    padding: 8px 10px;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid #ffffff94;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.8px;
  }
  .bottom-fix-toolbar {
    box-shadow: none;
    background-color: #20616d;
  }
  .desktop-hide {
    display: block;
  }
  .drop-down-template.pdc {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .final-structure-container.container.pt-5.text-white {
    padding-top: 0px !important;
  }
  .scroll-resume-box .resume-template-box .name-title {
    padding-left: 10px;
  }
  .scroll-resume-box .styleTempDBSeven .parent-col:nth-of-type(2),
  .scroll-resume-box .styleTempDBNine .parent-col:nth-of-type(2) {
    padding-top: 44px !important;
  }
  .scroll-resume-box .resume-preview .resume-zoom .dummy-img {
    height: 40px !important;
    width: 40px !important;
  }
  .scroll-resume-box .styleTempFour .profile-content .resume-social-info {
    padding-left: 15px;
  }
  .scroll-resume-box .resume-template-box .name-title {
    padding-left: 0px;
  }
  .mobile-screen-hd {
    display: block !important;
  }
  .desktop-screen-hd {
    display: none;
  }
  .mob-hide-header {
    display: none;
  }
  .choose-template-section.template-grid {
    background: #165c67;
  }
  .choose-template-section.template-grid .template-color-code {
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .choose-template-section.template-grid
    h6.clr-head.semi-bold.inline-block.uppercase {
    display: none;
  }
  .choose-template-section.template-grid .inline-block.resume-color-list {
    margin-bottom: 0px;
    padding: 15px 0px;
    padding-top: 18px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .choose-template-section.template-grid .mob-col-lg-8 {
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .choose-template-section.template-grid .text-whilte {
    color: #fff;
  }
  .choose-template-section.template-grid .p-title {
    font-weight: 600 !important;
    font-size: 23px !important;
  }
  .row.mt-5.resume-box.resume-preview-box {
    margin-top: 0px !important;
    padding-bottom: 40px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .choose-template-section.template-grid .resume-temp .resume-select-btn {
    min-width: 110px;
    position: fixed;
    bottom: 5px;
    z-index: 99 !important;
    width: 100%;
    left: 0;
    transform: none;
    top: auto;
    display: none;
    bottom: -100%;
  }
  .choose-template-section.template-grid
    .resume-temp.active
    .resume-select-btn.active-btn-position {
    bottom: 2%;
    display: block;
  }
  .custom-padding-bottom {
    padding-bottom: 50px !important;
  }
  .resume-content-one .parent-col:nth-of-type(1),
  .temp-4-pd .parent-col:nth-of-type(1) {
    margin-bottom: -1px;
  }
  .choose-template-section.template-grid
    .inline-block.resume-color-list
    .color-selector {
    margin-right: 0px;
  }
  .choose-template-section.template-section .col-lg-4.mb-3 {
    padding: 3px 8px;
    margin-bottom: 10px !important;
  }
  .row.static--btn-box .btn.site-btn.border-btn,
  .row.static--btn-box .border-btn,
  .row.static--btn-box .btn.site-btn.bg-white.text-black {
    display: none;
  }
  .row.static--btn-box {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .row.static--btn-box .col-sm-6.col-6.text-right,
  .row.static--btn-box .col-sm-8.col-10,
  .col-sm-6.col-8.text-right,
  .row.static--btn-box .col-sm-6 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0px;
  }

  .row.static--btn-box .btn.bg-blue {
    margin-left: 0px !important;
  }
  .row.sgnature-row .col-lg-6 {
    flex: 0 0 50%;
    width: 50%;
  }
  p.mleft-3 {
    display: none;
  }
  .tab-header button.active:before {
    background-color: #2926a5;
  }
  .tab-header button {
    padding-left: 0px;
    padding-right: 0px;
  }
  .final-resume-container {
    padding-bottom: 90px;
    padding-top: 70px;
  }
  .bottom-fix-toolbar .next-btn .site-btn {
    font-size: 16px !important;
    padding: 12px 25px !important;
    font-weight: 600 !important;
  }
  /* .mobile-side-bar {
    display: none;
} */

  .mobile-side-bar {
    position: fixed;
    top: 0;
    z-index: 99;
    background: #fff;
    width: 100%;
    height: 100%;
    right: -100%;
    transition: all 0.3s ease-in-out;
    padding: 15px;
    overflow-y: scroll;
  }
  .mobile-side-bar.active {
    right: 0%;
    transition: all 0.3s ease-in-out;
  }
  .sidebar-link a,
  .sidebar-link button {
    color: #000 !important;
  }
  .sidebar-link {
    padding-top: 20px;
  }
  .sidebar-title {
    color: #000;
    margin: 20px 0px;
    font-size: 20px !important;
  }
  .sidebar-back-btn {
    padding: 0px;
    border: none;
    background-color: transparent;
    position: absolute;
  }
  .resume-slide-box.scroll-resume-box {
    width: 100%;
    top: 0 !important;
    bottom: auto;
    height: 100%;
    margin-top: 0px;
    z-index: 9;
    background-color: #165c67;
    right: -100%;
    border-left: none;
    padding: 15px;
  }
  .scroll-resume-box .resume-preview {
    padding: 0px;
    border: 3px solid transparent;
  }
  .resume-slide-box .active-slide-resume.resume-preview {
    border: 3px solid #ffa62b;
  }
  .resume-slide-box.scroll-resume-box.active {
    right: 0%;
  }
  .desktop-show {
    display: none;
  }
  .modal-dialog.modal-dialog.resume-pre-dialog.profile-img-dialog {
    margin: 0px;
    position: fixed;
  }
  .modal-dialog.modal-dialog.resume-pre-dialog.profile-img-dialog
    .modal-content {
    height: 100vh !important;
    width: 100%;
  }
  .resume-pre-dialog .modal-content::-webkit-scrollbar {
    display: none;
  }
  /* start slider resume css */
  .scroll-resume-box .resume-preview .resume-zoom.styleTempDBNine .dummy-img {
    height: 40px !important;
    width: 40px !important;
  }
  .scroll-resume-box .resume-heading {
    font-size: 4px !important;
    margin-bottom: 1px !important;
    border-bottom: none !important;
    padding-bottom: 2px !important;
  }
  .scroll-resume-box .styleTempThree .resume-heading,
  .scroll-resume-box .template-grid .styleTempOne .resume-heading {
    border-bottom: 1px solid #ccc !important;
    margin-bottom: 4px !important;
  }
  .scroll-resume-box .resume-preview .resume-zoom.styleTempDBEight .dummy-img {
    height: 60px !important;
  }
  .scroll-resume-box .paragraph {
    font-size: 3px !important;
  }
  .resume-slide-box .resume-zoom ul {
    padding-left: 9px !important;
  }
  .slider-box-grid .col-lg-12.col-6.slider-box-grid-inner {
    padding: 3px 7px;
  }
  .scroll-resume-box .resume-content-one .parent-col:nth-of-type(3),
  .scroll-resume-box .temp-4-pd .parent-col:nth-of-type(3) {
    padding-left: 5px;
    padding-right: 5px;
  }
  .scroll-resume-box
    .choose-template-section
    .temp-4-pd
    .parent-col:nth-of-type(2) {
    padding-right: 5px;
  }
  .scroll-resume-box .choose-template-section .parent-col:nth-of-type(2) {
    padding-left: 7px;
  }
  .template-grid .styleTempDBSix .border-bg {
    margin-bottom: 1px !important;
  }
  .template-grid .styleTempDBEight .temp-4-pd .parent-col:nth-of-type(3) {
    padding-top: 2px !important;
  }
  .scroll-resume-box
    .template-grid
    .styleTempDBSix
    .parent-col:nth-of-type(1)
    .parent-right
    .name-title,
  .resume-zoom .styleTempDBSix .parent-right .name-title {
    padding-top: 3px;
  }
  .scroll-resume-box
    .styleTempDBSix
    .resume-content-box
    .parent-col:nth-of-type(2) {
    padding-top: 15px !important;
  }
  .scroll-resume-box .styleTempDBSix .parent-col:nth-of-type(3) {
    padding-top: 7px !important;
  }
  .scroll-resume-box .resume-template-box .name-title {
    font-size: 9px !important;
  }
  .scroll-resume-box .resume-zoom .temp-3 .top-fix-box {
    padding: 2% 7px !important;
    padding-left: 7px !important;
  }
  .paragraph,
  .scroll-resume-box
    .resume-zoom
    .resume-template-box.temp-3
    .socail-info.resume-social-info
    p {
    font-size: 3px;
  }
  .scroll-resume-box .resume-preview .resume-zoom.styleTempDBEleven .dummy-img,
  .scroll-resume-box .resume-preview .resume-zoom.styleTempDBTen .dummy-img {
    height: 35px !important;
  }
  .scroll-resume-box
    .template-grid
    .styleTempDBEleven
    .temp-4-pd
    .parent-col:nth-of-type(2) {
    padding-top: 20px !important;
  }
  .resume-slide-box .resume-zoom .percent-clr {
    height: 1px;
  }
  .scroll-resume-box .styleTempDBTen .temp-4-pd .parent-col:nth-of-type(2) {
    padding-top: 32px !important;
  }
  .scroll-resume-box .resume-preview .resume-zoom.styleTempDBSeven .dummy-img {
    height: 35px !important;
    width: 35px !important;
  }
  .scroll-resume-box .styleTempDBSeven .parent-col:nth-of-type(2) {
    padding-top: 22px !important;
  }
  .scroll-resume-box .styleTempDBTwelve .profile-pic .addNew .dummy-img {
    width: 25px !important;
    height: 25px !important;
  }
  .template-grid .styleTempOne .styled-bg,
  .template-grid .styleTempTwo .border-bg,
  .template-grid .styleTempFour .border-bg {
    padding: 0.1px;
  }
  .template-grid .styleTempOne .styled-bg,
  .template-grid .styleTempTwo .border-bg,
  .template-grid .styleTempFour .border-bg {
    margin-bottom: 2px !important;
  }
  .template-grid .styleTempFive .profile-content {
    align-items: center;
  }
  .resume-slide-box.scroll-resume-box .resume-slide-btn {
    display: none;
  }
  .resume-slide-box.scroll-resume-box.active .resume-slide-btn {
    display: block;
  }
  .resume-slide-btn .formating-box.color-formatting {
    position: relative;
    left: 0;
  }
  .resume-slide-btn .inline-block.resume-color-list {
    position: static;
    padding: 0;
  }
  .resume-slide-btn .color-selector {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .resume-slide-btn .inline-block.resume-color-list {
    position: static;
    padding: 0;
    display: flex;
    justify-content: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    background-color: transparent;
    box-shadow: none !important;
    border: none;
    margin-bottom: 8px;
  }
  .home-btn .custm-btn,
  .resume-select-btn .site-btn {
    min-height: 47px;
    max-width: 100%;
  }
  .custom-padding-bottom {
    padding-bottom: 62px !important;
  }
  .choose-template-section.template-grid .resume-temp .resume-select-btn {
        /* margin-left: 15px; */
    /* margin-right: 15px; */
    padding: 0px 15px;
}
  .static--btn-box {
        padding: 10px 15px;
      }
  .col-sm-6.col-8.manage--width.pr-3,
  .col-sm-6.col-10.text-right.wrk-hst-btn.site-btn--box {
    padding-left: 0px;
    padding-right: 0px !important;
}
  /* end slider resume css */
}
/* resume test-css */
@media screen and (max-width: 576px) {
  .range-text {
    font-size: 9px;
  }
  .lang-range-slider {
    width: 90%;
  }
  .proficency-range .MuiSlider-markLabel {
    top: -20px !important;
  }
  .range-text .label-value {
    left: -25px;
    top: 50px;
  }
  .profile-img-resume-contact {
    flex-direction: column;
  }
  .profile-img-box {
    margin-right: 0px;
    margin-bottom: 25px;
  }
  .profile-img-box .w-100 {
    width: 50% !important;
  }
  .resume-slide-box {
    bottom: 90px;
  }
  .drop-down-template {
    padding: 5px 8px !important;
    padding-right: 18px !important;
  }

  .active-slide .label-name,
  .active-slide .label-value {
    font-size: 8px;
    font-weight: 700;
  }
}

/* snip loader */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background:var(--blue);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader */
.loader-box {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid var(--blue);
  width: 65px;
  height: 65px; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* page loader */
.loader-box-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.loader-content {
  width: 40px;
  aspect-ratio: 1;
  display: grid;
}
.loader-content::before,
.loader-content::after {
  content: "";
  grid-area: 1/1;
  --c:no-repeat linear-gradient(#046D8B 0 0);
  background:
    var(--c) 0 0,
    var(--c) 100% 0,
    var(--c) 100% 100%,
    var(--c) 0 100%;
  animation: 
    l10-1 2s infinite linear,
    l10-2 2s infinite linear;
}
.loader-content::after {
  margin: 25%;
  transform: scale(-1);
}
@keyframes l10-1 {
  0%   {background-size: 0    4px,4px 0   ,0    4px,4px 0   }
  12.5%{background-size: 100% 4px,4px 0   ,0    4px,4px 0   }
  25%  {background-size: 100% 4px,4px 100%,0    4px,4px 0   }
  37.5%{background-size: 100% 4px,4px 100%,100% 4px,4px 0   }
  45%,
  55%  {background-size: 100% 4px,4px 100%,100% 4px,4px 100%}
  62.5%{background-size: 0    4px,4px 100%,100% 4px,4px 100%}
  75%  {background-size: 0    4px,4px 0   ,100% 4px,4px 100%}
  87.5%{background-size: 0    4px,4px 0   ,0    4px,4px 100%}
  100% {background-size: 0    4px,4px 0   ,0    4px,4px 0   }
}

@keyframes l10-2 {
  0%,49.9%{background-position: 0 0   ,100% 0   ,100% 100%,0 100%}
  50%,100%{background-position: 100% 0,100% 100%,0    100%,0 0   }
}
.paymentContainer {
    display: grid;
    place-items: center;
    background-color: rgb(255, 255, 255);
    height: 65vh;
    margin: 2vmax;
  }
  
  .paymentForm {
    width: 22%;
    height: 100%;
  }
  
  .paymentForm .des-text {
    color: #000;
    border-top: 1px solid rgba(0, 0, 0, 0.13);
    padding: 1vmax 0;
    text-align: center;
    width: 50%;
    margin-top: 20px;
  }
  
  .paymentForm .flex-div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .paymentInput {
    padding: 1vmax 4vmax;
    padding-right: 1vmax;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.267);
    border-radius: 4px;
    outline: none;
  }
  
  .paymentForm  .flex-div  svg,
  .paymentForm  .flex-div .card-icon,
     .paymentForm  .flex-div .key-icon {
    position: absolute;
    transform: translateX(1vmax);
    font-size: 1.6vmax;
    color: rgba(0, 0, 0, 0.623);
  }
.card-icon {
    width: 22px;
    top:33px
}
.key-icon {
    margin-right: 5px;
    width: 32px;
    top: 32px;
}
  .pay-btn {
    line-height: 0 !important;
}
  .paymentFormBtn {
    border: none;
    background-color: tomato;
    color: white;
    font: 300 0.9vmax ;
    padding: 0.8vmax;
    cursor: pointer;
    transition: all 0.5s;
    outline: none;
  }
  
  .paymentFormBtn:hover {
    background-color: rgb(179, 66, 46);
  }
  sub.text-sm {
    font-size: 53%;
}
.text-sm {
    font-size: 12px !important;
}
.text-xs {
    font-size: 10px !important;
}
  @media screen and (max-width: 600px) {
    .paymentForm {
      width: 90%;
    }
  
    .paymentForm > p {
      padding: 4vw 0;
      width: 60%;
    }
  
    .paymentForm .flex-div {
      margin-bottom: 10px;
    }
  
    .paymentInput {
      padding: 4vw 10vw;
    }
  
    .paymentForm  .flex-div svg,
     .paymentForm  .flex-div .card-icon,
     .paymentForm  .flex-div .key-icon{
      font-size: 6vw;
    }
  
    .paymentFormBtn {
      font: 300 4vw ;
      padding: 4vw;
    }
  }
